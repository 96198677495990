/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";
// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
// Theme customization
@import "theme/theme";

// -----------------------------------
html,
body,
app-root {
  height: 100%;
}
body,
button,
input,
optgroup,
select,
textarea {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}
//------------NEW CSS---------------------------------------
*,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
label:focus,
label {
  outline: none !important;
}
.spinnerAttach {
  bottom: 3px;
  position: relative;
}
.imageBox {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.premiumPara {
  color: #39394d;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 33px;
  margin-top: 48px;
}
.homeLeftCover {
  float: left;
  padding-right: 120px;
  width: 100%;
}
.orText {
  color: #39394d;
  float: left;
  font-weight: 400;
  margin: 10px 0;
  text-align: center;
  width: 100%;
}

*,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
body,
button,
input,
optgroup,
select,
textarea {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}
header {
  position: relative;
  z-index: 999;
}

.customNavbar {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  height: 70px;
  margin-bottom: 0px !important;
  opacity: 1;
  padding: 0.5rem 0;
}
.navbar-brand img {
  max-width: 80px;
}
.customNavCollapse {
  flex-grow: 0;
}
.homeCover {
  float: left;
  margin-top: 100px;
  width: 100%;
}
.homeLeftCover {
  float: left;
  padding-right: 195px;
  width: 100%;
}
canvas {
  max-width: 100%;
  padding: 20px;
}
.homeRightCover {
  float: left;
  width: 100%;
}
.meeting-img {
  width: 100%;
}
.premiumTitle {
  color: #39394d;
  font-size: 42px;
  font-weight: 600;
  line-height: 57px;
  margin-top: 10px;
}
.orText {
  color: #39394d;
  float: left;
  font-weight: 400;
  margin: 10px 0;
  text-align: center;
  width: 100%;
}
.newMeetingBtn {
  background-color: transparent;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  border-radius: 7px !important;
  color: #adadb7;
  font-size: 15px;
  font-weight: 400;
  height: 80px;
  width: 100%;
}
input.newMeetingBtn {
  background-color: transparent;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  border-radius: 7px !important;
  color: #adadb7;
  font-size: 15px;
  font-weight: 400;
  height: 45px;
  width: 100%;
}
.signinMainCover {
  float: left;
  margin-bottom: 20px;
  margin-top: 70px;
  width: 100%;
}
.signinCover {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}
.signinLogo {
  float: left;
  text-align: center;
  width: 100%;
}
.logoImg {
  width: 115px;
}
.formCover {
  float: left;
  width: 100%;
}
.signinText {
  color: #36b7bd;
  float: left;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 30px;
  width: 100%;
}
.formLabelText {
  color: #39394d;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-transform: capitalize;
}
.formCover .formInput,
.formCover .formInput:hover,
.formCover .formInput:focus,
.formSelect,
.formSelect:hover,
.formSelect:focus {
  border: 1px solid #c0ccda;
  border-radius: 5px;
  box-shadow: none;
  color: #39394d;
  font-size: 14px;
  height: 38px;
  outline: none;
}
.videoImg {
  margin-right: 10px;
  margin-top: -2px;
  width: 30px;
}
.forgotText,
.forgotText:hover,
.forgotText:focus {
  color: #36b7bd;
  text-decoration: none;
}
.signinBtn.skyblueBtn {
  width: 100%;
}
.signupLink {
  color: #39394d;
  float: left;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
  text-align: center;
  width: 100%;
}
.signupLink a,
.signupLink a:hover,
.signupLink a:focus {
  color: #36b7bd;
  font-weight: 700;
  text-decoration: none;
}
.termPolicyText {
  margin-bottom: 20px;
  text-align: left;
}
.passReset {
  margin-bottom: 5px;
}
.passResetText {
  color: #39394d;
  float: left;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 30px;
  width: 100%;
}
.customBageLink {
  position: relative;
}
.badgecustom {
  border-radius: 50%;
  display: inline-block !important;
  height: 6px;
  padding: 0;
  position: absolute;
  top: 5px;
  width: 6px;
}
.customdashLink li.nav-item {
  padding-left: 10px;
  padding-right: 10px;
}
.customdashLink li.nav-item:first-child {
  padding-left: 0px;
}
.customdashLink li.nav-item:last-child {
  padding-right: 0px;
}
.user-img > img {
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  position: relative;
  width: 40px;
  z-index: 999;
}
.userCover {
  background-color: #f4f4fa;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  display: inline-block;
  margin-left: -10px;
  padding: 1px 7px;
}
.user_Title {
  color: #39394d;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  margin-top: -2px;
  padding-left: 4px;
  vertical-align: middle;
}
.userCover i {
  color: #39394d;
}
.nav-link.customBageLink {
  margin-top: 9px;
}
.dashboardCover {
  background-color: #f9fafc;
  clear: both;
  display: flex;
  flex-direction: row;
  height: calc(100% - 100px);
  // height: 89vh;
}
.dashLeftCover {
  background-color: #f1f3f9;
  height: 100%;
  width: 100px;
}
.dashRightCover {
  width: 440px;
}
.pl_0 {
  padding-left: 0;
}
.dashLeftCover ul {
  list-style: none;
  margin-bottom: 0;
}
.dashLeftCover ul li a {
  border-left: 3px solid transparent;
  padding: 15px 5px 7px 5px;
  transition: all 0.2s ease-in-out;
}
.dashLeftCover ul li.active a,
.dashLeftCover ul li a:hover {
  background-color: #e5e9f2;
  border-left: 3px solid #36b7bd;
}
.dashLeftCover ul li.active a .sideLink,
.dashLeftCover ul li:hover a .sideLink {
  color: #39394d;
}
.dashLeftCover ul li a,
.dashLeftCover ul li a:hover,
.dashLeftCover ul li a:focus {
  color: #8392a5;
  display: block;
  text-align: center;
  text-decoration: none;
}
.dashLeftImg {
  width: 16px;
}
.sideLink {
  display: inline-block;
  font-size: 13px;
  width: 100%;
}
// .dashmidSubCover{margin-left: 100px;margin-top: 100px;max-width: 430px;padding: 15px;width: 500px;}
.dashmidCover .skyblueBtn {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  height: 80px;
  margin-top: 30px;
  width: 100%;
}
.welcomDashTitle {
  color: #39394d;
  font-size: 24px;
  font-weight: 600;
  line-height: 46px;
}
.dashRightSubCover {
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0 0 0 1px #f1f1f1;
  float: left;
  margin-bottom: 60px;
  margin-top: 40px;
  width: calc(100% - 40px);
}
.dashRightTitle {
  border-bottom: 1px solid #e5e9f2;
  color: #39394d;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
}
.dashRightUlCover ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dashRightUlCover ul li {
  border-bottom: 1px solid #e5e9f2;
  float: left;
  padding-bottom: 20px;
  padding-top: 10px;
  width: 100%;
}
.dashRightUlCover ul li:last-child {
  border-bottom: none;
  padding-bottom: 40px;
}
.dayText {
  color: #39394d;
  font-size: 16px;
  font-weight: 600;
}
.meetingLeft {
  float: left;
  width: 70px;
}
.pinkTime,
.purpleTime,
.greenTime {
  background-color: #fbe7fc;
  border-radius: 10px;
  color: #e544ba;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 60px;
}
.purpleTime {
  background-color: #e7e7fc;
  color: #7b44e5;
}
.greenTime {
  background-color: #e7fcfc;
  color: #44e59f;
}
.meetingRight {
  float: left;
  padding-left: 15px;
  width: calc(100% - 70px);
}
.meetingTitle {
  color: #39394d;
  font-size: 16px;
  font-weight: 600;
}
.meetingTime {
  color: #8392a5;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}
.hostTitle {
  color: #39394d;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  text-transform: capitalize;
}
.meetingRight .skyblueBtn {
  margin-top: 10px;
  text-transform: capitalize;
  height: 44px;
  font-size: 16px;
  line-height: 25px;
  padding: 0 35px;
}
.meetingLRCover {
  float: left;
  margin-top: 15px;
  width: 100%;
}
.dashRightUlCover {
  float: left;
  padding: 0 20px;
  width: 100%;
}
.meetingDetailCover {
  float: left;
  padding-top: 20px;
  width: 100%;
}
.videoHeaderCvoer {
  background-color: #ffffff;
  border-bottom: 1px solid #f7f7f7;
  float: left;
  padding: 15px 40px;
  width: 100%;
}
.videoHeaderSubCvoer {
  float: left;
  width: 100%;
}
.discussText {
  color: #39394d;
  font-size: 20px;
  font-weight: 600;
  padding-left: 30px;
  vertical-align: middle;
}
.singleVideoCover {
  background-color: #edf1f4;
  float: left;
  padding: 15px 40px;
  width: 100%;
}
.singlVideoImg {
  float: left;
  height: calc(100vh - 92px);
  position: relative;
  width: 100%;
}
.singlVideoImg img.videoMeetingImg {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}
.chevronRight {
  background-color: #ffffff;
  border-radius: 50%;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: -11px;
  text-align: center;
  top: 7px;
  width: 20px;
}
.chevronRight img.arrowRightImg {
  width: 5px;
}
.secondImg {
  height: 122px;
  position: absolute;
  right: 11px;
  top: 0;
  top: 26px;
  width: 200px;
}
img.secondSingleImg {
  border-radius: 10px;
  height: 100%;
  max-height: 100%;
  width: 100%;
}
.callerName {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  left: 20px;
  position: absolute;
  top: 20px;
}
.setting {
  background-color: #5d6a6c;
  border-radius: 50%;
  bottom: 20px;
  cursor: pointer;
  height: 24px;
  left: 40px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  width: 24px;
  z-index: 1050;
}
.setting:hover {
  transform: scale(1.3);
}
img.settingImg {
  width: 16px;
}
.callerControl {
  bottom: 20px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.maximize {
  background-color: #5d6a6c;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  margin: 0 15px;
  text-align: center;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  width: 32px;
}
.maximize:hover {
  transform: scale(1.3);
}
img.maximizeImg {
  width: 16px;
}
.phone {
  background-color: #fd5755;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 0 15px;
  text-align: center;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  width: 40px;
}
.phone:hover {
  transform: scale(1.3);
}
img.phoneImg {
  width: 18px;
}
.caller_Meeting_Cover {
  float: left;
  width: 100%;
}
.caller_Meeting_Cover .singleVideoCover {
  float: left;
  width: 70%;
}
.chatTabCover {
  float: left;
  width: 30%;
}
.meetingHeaderSubCvoer {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
}
.meetingTitle {
  float: left;
  width: 100%;
}
.closeMiniMaxiCover {
  position: absolute;
  right: 0;
}
.closeMiniMaxiCover a {
  display: inline-block;
  padding: 0 7px;
}
.closeMiniMaxiCover a:first-child {
  padding-left: 0;
}
.closeMiniMaxiCover a:last-child {
  padding-right: 0;
}
.closeMiniMaxiCover a img {
  width: 11px;
}
.chatTabCover ul li {
  text-align: center;
  width: 50%;
}
.chatTabCover ul.nav-tabs li.nav-item {
  margin-bottom: 0;
}
.chatTabCover ul.nav-tabs li.nav-item a.nav-link {
  color: #081333;
  font-size: 14px;
  font-weight: 600;
}
.chatTabCover ul.nav-tabs li.nav-item a.nav-link.active,
.chatTabCover ul.nav-tabs li.nav-item a.nav-link:hover,
.chatTabCover ul.nav-tabs li.nav-item a.nav-link:focus {
  border-bottom-color: #36b7bd;
  border-color: transparent;
  color: #36b7bd;
}
.hostLeftCover {
  display: table;
  float: left;
  padding-right: 7px;
  width: calc(100% - 80px);
}
.hostRightCover {
  float: left;
  text-align: right;
  width: 80px;
}
.hostUserImg {
  display: table-cell;
  height: 38px;
  vertical-align: middle;
  width: 37px;
}
.hostUserImg img {
  border-radius: 7px;
  height: 100%;
  width: 100%;
}
.hostUserDetail {
  color: #39394d;
  display: table-cell;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding-left: 15px;
  text-transform: capitalize;
  vertical-align: middle;
  width: calc(100% - 47px);
}
.hostRightCover a {
  display: inline-block;
  line-height: 38px;
  padding: 0 10px;
}
.hostRightCover a:first-child {
  padding-left: 0;
}
.hostRightCover a:last-child {
  padding-right: 0;
}
.phoneMeeting {
  display: inline-block;
  position: relative;
  transition: all 2s ease-in-out;
}
.phoneMeeting .phone {
  margin: 0;
}
.leaveMeeting {
  background-color: #ffffff;
  border-bottom-left-radius: 0;
  border-radius: 10px;
  display: inline-block;
  display: none;
  left: 30px;
  padding: 5px 15px;
  position: absolute;
  text-align: left;
  top: -70px;
  width: 200px;
  z-index: 9;
}
.leaveMeetingText {
  color: #39394d;
  float: left;
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
}
.endMeetingText {
  color: #fd5755;
  float: left;
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
}
.phoneMeeting:hover .leaveMeeting {
  display: block;
}
.chatCallerName {
  color: #081333;
  float: left;
  font-size: 11px;
  font-weight: 600;
  padding-left: 45px;
  padding-top: 15px;
  width: 100%;
}
.callerMsgCover {
  float: left;
  margin-top: 10px;
  width: 100%;
}
.callerMsgImg {
  float: left;
  height: 30px;
  line-height: 45px;
  width: 30px;
}
.callerMsgImg img {
  border-radius: 50%;
  max-height: 100%;
  width: 100%;
}
.callerMsg {
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 1px 4px #0000001a;
  color: #081333;
  float: left;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  line-height: 45px;
  margin-left: 15px;
  min-width: 90px;
  padding: 0 15px;
  text-align: center;
}
.callerRightMsg {
  float: left;
  margin-top: 15px;
  width: 100%;
}
.callerMsgBckColor {
  background-color: #d0d3e3;
  float: right;
}
.chatMsgCover {
  float: left;
  height: calc(100vh - 200px);
  overflow: auto;
  padding: 10px 20px 20px 20px;
  width: 100%;
}
.participantCover {
  float: left;
  padding: 10px 20px 20px 20px;
  width: 100%;
}
.chatMsgMainCover {
  float: left;
  width: 100%;
}
.inputMsgCover {
  float: left;
  padding: 10px 20px 10px 20px;
  position: relative;
  width: 100%;
}
.inputMsgSubCover {
  border: 1px solid #c0ccda;
  border-radius: 8px;
  box-shadow: 0px 1px 4px #0000001a;
  float: left;
  width: 100%;
}
.inputMsgSubCover input {
  border: none;
  border-radius: 11px;
  color: #39394d;
  float: left;
  font-size: 14px;
  height: 45px;
  padding-right: 5px;
  width: calc(100% - 42px);
}
.inputMsgSubCover input:hover,
.inputMsgSubCover input:focus {
  border-color: transparent;
  box-shadow: none;
  outline: none;
}
.sentImgCover {
  background-color: #36b7bd;
  border-radius: 7px;
  float: left;
  height: 40px;
  line-height: 40px;
  margin-right: 2px;
  margin-top: 2px;
  text-align: center;
  width: 40px;
}
.participantTileCover {
  float: left;
  width: 100%;
}
.inviteParticipantCover {
  float: left;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
.inviteBtn,
.inviteBtn:hover,
.inviteBtn:focus {
  background-color: #ffffff;
  border: 1px solid #c0ccda;
  border-radius: 10px;
  box-shadow: none;
  color: #39394d;
  font-size: 15px;
  font-weight: 600 !important;
  height: 40px;
  width: 166px;
}
.doubleVideo {
  padding: 15px 40px 80px 40px;
  position: relative;
}
.doubleVideo .singlVideoImg {
  height: calc(100vh - 150px);
  padding: 0 5px;
  width: 50%;
}
.allVideoCover {
  float: left;
  width: 100%;
}
.allControllCover {
  float: left;
  width: 100%;
}
.doubleVideo .setting {
  bottom: 26px;
}
.doubleVideo .secondImg {
  height: 80px;
  width: 120px;
}
.inputMsgSubCover.attachCover input {
  width: calc(100% - 71px);
}
.paperclipImgCover {
  float: left;
  margin-left: 7px;
  margin-top: 10px;
  width: 22px;
}
.sentImgCover a,
.paperclipImgCover a {
  display: block;
}
.germanText {
  color: #55fd87;
  font-size: 11px;
  font-weight: 500;
  left: 20px;
  position: absolute;
  top: 40px;
}
.chevronRightSide {
  position: relative;
}
.allParticipantCover,
.allParticipantCover {
  float: left;
  width: 100%;
}
.participantHostTitle {
  color: #36b7bd;
  float: left;
  font-size: 11px;
  font-weight: 500;
  margin-top: 3px;
  width: 100%;
}
.activateUserCover {
  float: left;
  margin-bottom: 10px;
  width: 100%;
}
.activateUserTitle {
  color: #081333;
  float: left;
  font-size: 15px;
  font-weight: 600;
  width: calc(100% - 90px);
}
.muteall {
  float: left;
  width: 90px;
}
img.mic-off-img {
  width: 20px;
}
.muteAllText {
  color: #39394d;
  font-size: 13px;
  font-weight: 600;
}
.allParticipantCover .participantTileCover {
  margin-top: 7px;
}
.allParticipantCover.chatMsgCover {
  padding: 0;
}
img.meetingMicImg {
  width: 14px;
}
img.meetingVideoImg {
  width: 20px;
}
img.threeDotsImg {
  width: 16px;
}
.hostRemove {
  position: relative;
}
.hostRemove .leaveMeeting {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0;
  box-shadow: 0px 3px 6px #00000029;
  display: none;
  left: -200px;
  position: absolute;
  top: -50px;
  transition: all 0.3s ease-in-out;
  width: 200px;
}
.hostRemove:hover .leaveMeeting {
  display: block;
}
.hostRemove .leaveMeeting .leaveMeetingText,
.hostRemove .leaveMeeting .endMeetingText {
  line-height: 13px;
  padding-bottom: 8px;
  padding-top: 8px;
}
.participantHostCover .participantTileCover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.participantHostCover .participantTileCover:hover {
  background-color: #e5e9f2;
}
.participantHostCover {
  padding: 0;
}
.participantHostCover .activateUserCover {
  margin-bottom: 0;
  padding: 10px 20px 10px 20px;
}
.participantHostCover .allParticipantCover .participantTileCover {
  margin-top: 0;
  padding: 5px 20px 5px 20px;
}
img.meetingMicOffImg {
  width: 18px;
}
.otherParticipantTitle {
  color: #081333;
  font-size: 16px;
  font-weight: 600;
}
.orangeText {
  color: #fdd655;
}
.leftMeetingCover {
  float: left;
  text-align: center;
  width: 100%;
}
.leftMeetingDetailCover {
  float: left;
  height: calc(100vh - 55px);
  position: relative;
  width: 100%;
}
.leftMeetingDetailSubCover {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.leftMeetingTitle {
  color: #39394d;
  float: left;
  font-size: 32px;
  font-weight: 600;
  width: 100%;
}
.leftMeetingBtnCover {
  float: left;
  margin-top: 40px;
  width: 100%;
  .skyblueBtn {
    margin: 0 5px;
    width: 200px;
  }
}
.cancelBtn,
.cancelBtn:hover {
  background-color: #ffffff;
  color: #36b7bd;
}

.leftMeetingBtnCover .skyblueBtn:hover {
  background: #36b7bd;
}

.blackScreen {
  background-color: #3a3a3a;
  border-radius: 10px;
  display: inline-block;
  float: none;
  max-height: 300px;
  max-width: 500px;
}
.blackScreenMSGCover .callerName {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
img.micWhiteImg {
  width: 12px;
}
.connectingModal {
}
.connectingModal .modal-dialog {
  left: 0;
  margin: 0 auto;
  max-width: 300px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) !important;
}
.connectingModal .modal-content {
  border-radius: 15px;
}
.connectingCover {
  float: left;
  margin: 40px 0;
  text-align: center;
  width: 100%;
}
.loadingImg {
  display: inline-block;
  max-width: 60px;
  width: 100%;
}
.loadingImg img {
  width: 100%;
}
.connectingTitle {
  color: #39394d;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  width: 100%;
}
.connectingPara {
  color: #39394d;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  width: 100%;
}
.modalCancelBtn {
  display: inline-block;
  margin-top: 20px;
  width: 100%;
}
.newMeetingModal .modal-content {
  border-radius: 20px;
}
.newMeetingModal .modal-header {
  border-bottom: none;
  padding: 40px 40px 16px 40px;
}
.newMeetingTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}
.newMeetingModal button.close {
  margin: 0;
  padding: 0;
}
.newMeetingModal button.close img {
  width: 18px;
}
.linkCover {
  float: left;
  position: relative;
  width: 100%;
}
.linkText {
  background-color: #f2f5f9;
  border-radius: 10px;
  color: #39394d;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  padding: 0 40px 0 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.linkCopyImg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.linkCopyImg img {
  width: 18px;
}
.newMeetingModal .modal-body {
  padding: 10px 40px 40px 40px;
}
.moreOptionText {
  float: left;
  margin-top: 20px;
  width: 100%;
}
.moreOptionText a,
.moreOptionText a:hover {
  color: #36b7bd;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}
.newMeetingModal .modal-dialog {
  left: 0;
  margin: 0 auto;
  max-width: 500px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) !important;
}
.joinId {
  color: #39394d;
  float: left;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: calc(100% - 80px);
}
.newMeetingBtn,
.newMeetingBtn:hover,
.newMeetingBtn:focus {
  box-shadow: none;
}
.joinText {
  float: left;
  text-align: right;
  width: 80px;
}
.joinText a,
.joinText a:hover {
  color: #36b7bd;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.alertImg img {
  width: 40px;
}
.startMeetHoverCover {
  float: left;
  position: relative;
  width: 100%;
}
.startMeetHoverDetail {
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 3px 6px #00000029;
  display: inline-block;
  display: none;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 70px;
  transition: all 0.3s ease-in-out;
  width: 300px;
}
.startMeetHoverCover button.skyblueBtn:hover + .startMeetHoverDetail {
  display: block;
}
.startMeetHoverDetail ul {
  list-style: none;
  margin-bottom: 0;
}
.startMeetHoverDetail ul li {
  float: left;
  width: 100%;
}
.startMeetHoverDetail ul li a {
  display: inline-block;
  padding: 10px;
  width: 100%;
}
.videoLinkImg {
  float: left;
  width: 18px;
}
.videoLinkImg img {
  width: 100%;
}
.videoLinkText {
  color: #39394d;
  float: left;
  font-size: 14px;
  font-weight: 600;
  margin-top: 4px;
  padding-left: 10px;
  width: calc(100% - 18px);
}
.preferencePara {
  color: #39394d;
  float: left;
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  width: 100%;
}
.formCheckCover {
  float: left;
  width: 100%;
}
.formCheckCover .form-check {
  display: inline-block;
  margin: 0 15px;
}
.formCheckCover .form-check:first-child {
  margin-left: 0;
}
.formCheckCover .form-check:last-child {
  margin-right: 0;
}
.hostImg img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
.preferenceBtnCover {
  float: left;
  margin-top: 20px;
  width: 100%;
}
.preferenceBtnCover button,
.preferenceBtnCover button:hover,
.preferenceBtnCover button:focus {
  float: left;
  margin-top: 15px;
  width: 100%;
}
.preferenceBtnCover .cancelBtn {
  border: 1px solid transparent;
}
.preferenceBtnCover .cancelBtn:hover {
  border: 1px solid #36b7bd;
}
.permissionCover {
  float: left;
  width: 100%;
}
.permissionTileCover {
  float: left;
  margin-top: 20px;
  width: 100%;
}
.permissionTileImg {
  float: left;
  width: 22px;
}
.permissionTileImg img {
  width: 100%;
}
img.microphoneImg {
  width: 18px;
}
img.permissionVideoImg {
  width: 24px;
}
.permissionTileDetail {
  float: left;
  padding-left: 15px;
  width: calc(100% - 22px);
}
.permissionTitle {
  color: #39394d;
  float: left;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.permissionPara {
  color: #39394d;
  float: left;
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  width: 100%;
}
.permissionModal .modal-dialog {
  max-width: 400px;
}
.permissionModal .modal-body {
  padding: 40px 35px 16px 35px;
}
.permissionModal .modal-body {
  padding: 10px 35px 25px 35px;
}
.chatmidCover {
  background-color: #ffffff;
  display: table-cell;
  vertical-align: top;
  width: 400px;
}
.chatRightCover {
  display: table-cell;
  vertical-align: top;
  width: calc(100% - 400px);
}
.chatmidCover .chatTabCover {
  width: 100%;
}
.msgCover {
  float: left;
  padding: 20px;
  width: 100%;
}
.msgText {
  float: left;
  width: calc(100% - 20px);
}
.msgTitle {
  color: #39394d;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding-right: 15px;
}
.msgBadge {
  background-color: #f95959;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
}
.msgSearchImg {
  float: left;
  width: 20px;
}
.msgSearchImg img {
  width: 100%;
}
.chatTabCover ul.nav-tabs {
  border-bottom: none;
  padding: 0 20px;
}
.groupText {
  color: #39394d;
  float: left;
  font-size: 15px;
  font-weight: 400;
  padding: 20px 20px 10px 20px;
  text-transform: uppercase;
  width: 100%;
}
.groupChatCover ul {
  list-style: none;
  margin-bottom: 0;
}
.chatGroupImg {
  float: left;
  margin-top: 15px;
  position: relative;
  text-align: left;
  width: 45px;
}
.chatGroupMsg {
  float: left;
  padding-left: 15px;
  text-align: left;
  width: calc(100% - 45px);
}
.oneImg {
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 30px;
  position: relative;
  width: 30px;
  z-index: 9;
}
.secondSpan {
  position: absolute;
  right: 0;
  top: 0;
}
.secondSpanImg {
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.groupChatTitle {
  float: left;
  text-align: left;
  width: 100%;
}
.groupChatName {
  color: #39394d;
  float: left;
  font-size: 14px;
  font-weight: 600;
  padding-right: 10px;
  width: calc(100% - 65px);
}
.groupChatTime {
  color: #39394d;
  float: left;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  width: 65px;
}
.groupChatCover ul li {
  border-bottom: 1px solid #e5e9f2;
  float: left;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.groupChatCover ul li:last-child {
  border-bottom: none;
}
.groupChatCover ul li:hover {
  background-color: #e1f4f5;
}
.groupChatPara {
  color: #8392a5;
  float: left;
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
  width: 100%;
}
.groupChatCover {
  padding: 10px 0px 20px 0px;
}
.plusImgCover {
  background-color: #f95959;
  border-radius: 50%;
  bottom: 25px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  height: 45px;
  line-height: 45px;
  position: absolute;
  right: 20px;
  text-align: center;
  width: 45px;
}
.plusImgCover img {
  width: 25px;
}
.groupChatMainCover {
  position: relative;
}
.chatRightHeaderCover {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  float: left;
  padding: 15px 20px;
  width: 100%;
}
.chatHeadTileCvoer {
  float: left;
  width: calc(100% - 200px);
}
.chatHeadContactCover {
  float: left;
  width: 200px;
}
.chatHeadTileCvoer ul {
  list-style: none;
  margin-bottom: 0;
}
.statusText {
  color: #36b7bd;
  float: left;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
}
.chatHeadTileCvoer .chatGroupImg {
  margin-top: 0;
}
.chatHeadContactCover ul {
  list-style: none;
  margin-bottom: 0;
}
.chatHeadContactCover ul li {
  display: inline-block;
  padding: 0 10px;
}
.chatHeadContactCover ul li:first-child {
  padding-left: 0;
}
.chatHeadContactCover ul li:last-child {
  padding-right: 0;
}
.chatHeadContactCover ul li a {
  background-color: #f4f4fa;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}
img.videoGrayImg {
  width: 20px;
}
img.phoneGrayImg {
  width: 18px;
}
img.threeVerticalImg {
  height: 16px;
  width: 16px;
}
.chatRightCover .chatMsgCover {
  height: calc(100vh - 215px);
}
.attendMeetingCover {
  float: left;
  padding: 20px 0;
  width: 100%;
}
.attendMeetingLinkCover {
  float: left;
  width: 100%;
}
.attendMeetLindSubCover {
  float: left;
  margin-bottom: 15px;
  max-width: 485px;
  width: 100%;
}
.attendMeetingTitle {
  color: #000000;
  float: left;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}
.agentComm {
  max-width: 85%;
  width: 100%;
}
.agentComm .agentChat {
  max-width: 100% !important;
  width: 100% !important;
}
.adminComm {
  max-width: 90%;
}
.attendMeetingLinkCover .linkCover {
  margin-top: 30px;
}
.attendMeetingLinkCover .formCover .leftMeetingBtnCover {
  margin-top: 20px;
}
.attendMeetingLinkCover .formCover .leftMeetingBtnCover .skyblueBtn {
  width: 115px;
}
.attendMeetingLinkCover .formCover .leftMeetingBtnCover .skyblueBtn:first-child {
  margin-left: 0;
}
.attendMeetingLinkCover .formCover .leftMeetingBtnCover .skyblueBtn:last-child {
  margin-right: 0;
}
.attendFormCover {
  float: left;
  width: 100%;
}
.attendFormLeftCover {
  float: left;
  padding-right: 15px;
  width: 45%;
}
.attendFormRightCover {
  float: left;
  padding-left: 15px;
  width: 45%;
}
.inviteUserWrapper {
  float: left;
  margin-top: 10px;
  width: 100%;
}
.inviteUserWrapper ul {
  list-style: none;
  margin-bottom: 0;
}
.inviteUserWrapper ul li {
  display: inline-block;
}
.inviteUserWrapper ul li a.nav-link {
  padding: 5px 0;
}
.inviteUserWrapper ul li a.nav-link .userCover {
  margin-top: 0;
}
.spinner-border {
  color: #36b7bd;
}
.home-modal .home-modal-bdy {
  padding: 50px 30px;
}
.home-modal .home-modal-bdy img {
  margin-bottom: 55px;
}
.home-modal .home-modal-bdy h4 {
  margin-bottom: 40px;
}
.home-modal .home-modal-bdy p {
  margin-bottom: 40px;
}
.meting-modal img {
  width: 100px;
}

// -------NEW CSS ENDS HERE---------------------------

.modal-content {
  border-radius: 35px;
  padding: 20px;
}
.input-error {
  color: red;
  font-size: 12px;
  padding-top: 5px;
  width: 100%;
}
.metting-btns-main .popover {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  max-width: 529px !important;
  width: 100%;
}
.metting-btns-main .popover-header,
.metting-btns-main .popover-body {
  background-color: transparent;
  color: #39394d;
  font: normal normal 600 22px/33px Poppins;
  padding: 15px;
}
.metting-btns-main .popover .arrow {
  display: none;
}
.modal-content {
  padding: 0px;
}
// .modal-fixed-height .modal-content {
//   height: 90vh !important;
// }
.new-metng-link-modal {
  max-width: 812px !important;
}
.new-metng-link-modal .modal-body {
  padding: 0px 20px 20px 20px;
}
.appcameraComponent video {
  border: 4px solid #ffffff5e;
  border-radius: 15px;
  height: 100%;
  height: 190px !important;
  left: 0;
  margin-left: 0px !important;
  object-fit: cover;
  position: absolute;
  right: 90px;
  top: 0;
  top: 90px;
  width: 100% !important;
  width: 100%;
  width: 279px !important;
  z-index: 9;
}
.participents-box {
  display: grid;
  // grid-gap: 20px;
  // grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  height: 90vh;
  // position: relative;
}
button:focus {
  outline: none;
}
.mainVideoBox {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  position: relative;
}
.translatorWith video,
.transDiv video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.participents-box video {
  align-items: flex-start !important;
  height: 100%;
  object-fit: cover;
  padding: 0;
  width: 100%;
  position: absolute;
  align-self: flex-start !important;
}
.opened {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  position: relative;
}
.opened .userDetails {
  flex-grow: 1;
  margin-left: 18px;
}
.opened div button:first-child {
  margin-right: 40px;
}
.opened div button {
  background: transparent;
  border: none;
  box-shadow: none;
}
.parent-box-host .singleUser #preview video {
  height: 90vh;
  object-fit: cover;
  width: 100%;
}
video {
  moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  webkit-transform: rotateY(180deg);
}
.userDetails small {
  color: #36b7bd;
  display: block;
  font: normal normal medium 12px/18px Poppins;
  letter-spacing: 0px;
  text-align: left;
}
// .moreThenTwo .participents-box video {
//   height: 45vh;
// }
.multiUser video {
  height: 100%;
  width: 100%;
}
.opened:hover,
.hostInfo:hover {
  background: #e5e9f2;
}
.hoverDots {
  cursor: pointer;
  padding-right: 0;
  position: absolute;
  right: 30px;
  top: 35%;
}
.dropdown-toggle::after {
  display: none;
}
.removefromMeeting {
  color: #fd5755;
}
.participantsInfo .dropdown-menu.dropdown-menu-right {
  right: 0 !important;
}
.participantsInfo .dropdown-menu.dropdown-menu-right.show {
  border-radius: 20px 0px 20px 10px;
  overflow: hidden;
  right: 25px !important;
  top: 5px !important;
  width: 275px;
}
.header-logo img {
  max-width: 80px;
}
.actvParticipant,
.otherParticipants {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 40px;
}
.appVideohome {
  flex-grow: 1;
}
.appHome {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.joinBtn {
  background: none;
  border: none;
  box-shadow: none;
  color: #36b7bd;
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 999;
  text-align: right;
}
.useractionDropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  background: red;
  border-radius: 20px 0px 20px 10px;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  right: 50px;
  top: 20px;
  width: 273px;
  z-index: 999;
}
.modal-lg-login.signupModal {
  max-width: 1457px !important;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: none;
}
.error-container {
  color: #dc3545;
  font-size: 12px;
  margin: 5px 15px;
}
.btn-link {
  padding: 3px 0 !important;
}

// front header css

.navbar-brand img {
  max-width: 80px;
}
header {
  position: relative;
  z-index: 999;
}
.customNavCollapse {
  flex-grow: 0;
}
.navbar-light .customColl .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}
.navbar-light .customColl .navbar-nav .nav-link {
  color: #39394d;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 27px;
  margin-right: 54px;
  text-align: center;
  white-space: nowrap;
}
.navbar-light .customColl .navbar-nav .nav-item:last-child a {
  margin-right: 0px;
}
.skyblueBtn {
  background: #36b7bd 0% 0% no-repeat padding-box;
  border: 1px solid #36b7bd;
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0px;
  line-height: 27px;
  text-align: center;
  width: 100%;
}
.skyblueBtn:hover {
  color: #fff;
}
.newMeetingBig {
  background: #36b7bd 0% 0% no-repeat padding-box;
  border: 1px solid #36b7bd;
  border-radius: 10px;
  height: 80px;
  margin-top: 80px;
  width: 100%;
}
.mainRightCover {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sign-left-modal {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  width: 100%;
}
.signUp-main h3 {
  color: #39394d;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 33px;
  margin-bottom: 40px;
  text-align: left;
  text-transform: capitalize;
}
.signUp-main label {
  color: #39394d;
  font-size: 17px;
  line-height: 23px;
}
.signUp-main .input-group input {
  border: 1px solid #c0ccda;
  border-radius: 5px;
  box-shadow: none;
  font-size: 13px;
  height: 50px;
  outline: none;
}
.sign-up-btn {
  background: #36b7bd 0% 0% no-repeat padding-box;
  border: 1px solid #36b7bd;
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  height: 60px;
  letter-spacing: 0px;
  line-height: 27px;
  text-align: center;
  width: 100%;
}
.sign-up-btn:hover {
  color: #ffffff !important;
}
.arledy-signup-txt {
  color: #39394d;
  cursor: pointer;
  font-size: 17px;
  line-height: 40px;
}
.signup-prvcy-txt {
  color: #39394d;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 27px;
}
.signUp-main .input-group img {
  position: absolute;
  right: 15px;
  top: 18px;
  z-index: 9;
}
.signup-prvcy-txt a {
  color: #36b7bd !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.arledy-signup-txt {
  color: #39394d;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 40px;
}
.arledy-signup-txt a {
  color: #36b7bd !important;
  cursor: pointer;
  font-size: 18px;
  line-height: 40px;
}
.signUp-main .input-group select {
  border: 1px solid #c0ccda;
  border-radius: 10px;
  height: 50px;
  moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  webkit-appearance: none;
}
.signUp-main .input-group img {
  position: absolute;
  right: 15px;
  top: 18px;
  z-index: 9;
}
.sign-right-modal {
  align-items: center;
  background: #f1f3f9 0% 0% no-repeat padding-box;
  border-bottom-right-radius: 35px;
  border-top-right-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px !important;
  padding: 50px 0px;
}
i.fas.fa-calendar-week {
  color: #000;
}
button.btn.btn-outline-secondary.calendar:hover {
  background: transparent;
}
button.btn.btn-outline-secondary.calendar {
  border: none;
  position: relative;
  text-align: right;
  top: -4px;
  width: 100%;
  z-index: 9999;
  padding-right: 30px;
}
// .input-group-append.claendar-div {
//   position: absolute;
//   right: 5px;
//   top: 6px;
//   width: 100%;
// }
.navbar-light .customColl .navbar-nav .nav-link.customBageLink {
  background: #f4f4fa;
  border-radius: 50%;
  font-size: inherit;
  height: 40px;
  line-height: inherit;
  text-align: center;
  width: 40px;
}
.customBageLink img {
  height: 20px;
  width: 18px;
}
.partName {
  color: #fff;
  font-size: 18px;
  left: 40px;
  line-height: 27px;
  position: absolute;
  text-transform: capitalize;
  top: 20px;
  z-index: 999;
}
.transDiv p.partName {
  color: #fff;
  font-size: 14px;
  left: 10px;
  line-height: 20px;
  position: absolute;
  text-transform: capitalize;
  top: 10px;
  z-index: 999;
}
.transDiv .transTag {
  position: absolute;
  color: #55fd87;
  top: 25px;
  left: 10px;
  z-index: 999;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
}
.transTag {
  color: #55fd87;
  font-size: 14px;
  left: 40px;
  line-height: 21px;
  position: absolute;
  text-transform: capitalize;
  top: 65px;
  z-index: 999;
}

//video page css

.parent-box-host {
  padding: 50px 60px;
}
.parent-all-cam {
  height: 100vh;
}
.grid-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 0fr 4fr 1fr;
  grid-template-rows: 0fr 7fr 0fr;
  grid-template-areas: "top-left . top-right" "left content right" "bottom-left . bottom-right";
  background: #edf1f4 0% 0% no-repeat padding-box;
}
.grid-content {
  grid-area: content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(56, 56, 56);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.grid-left {
  grid-area: left;
  background: linear-gradient(to left, rgb(56, 56, 56) 0, transparent 100%);
}

.grid-right {
  grid-area: right;
  background: linear-gradient(to right, #383838 0, #383838 100%);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.grid-top-left,
.grid-top-right,
.grid-bottom-left,
.grid-bottom-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-top-left {
  grid-area: top-left;
}
.grid-top-right {
  grid-area: top-right;
}
.grid-bottom-left {
  grid-area: bottom-left;
}
.grid-bottom-right {
  grid-area: bottom-right;
}
.grid-container {
  padding: 0px;
}
.add-other-user-right {
  position: absolute;
  right: 0px;
  width: 25%;
  top: 90px;
}
// .chatbox-parent {
//   overflow-y: scroll;
// }
.chatbox-toggle-btn {
  width: 33px;
  height: 33px;
  z-index: 990;
  background: #edf1f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  border: none;
  position: absolute;
  right: -15px;
  top: 20px;
}
.parent-box-host {
  width: 100%;
  height: 100%;
  position: relative;
}
.btns-nav {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  z-index: 1050;
  text-align: center;
}
.btns-nav button {
  margin: 0 15px;
}
.btns-nav-one {
  position: absolute;
  bottom: 100px;
  left: 150px;
  z-index: 1051;
}
.leave-room {
  line-height: 75px;
  width: 83px;
  height: 83px;
  background: #fd5755 0% 0% no-repeat padding-box;
  border-radius: 50%;
  border: none;
}
.mute {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #a7adaf;
  opacity: 1;
  border: none;
}
.unmute {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #a7adaf;
  opacity: 1;
  border: none;
}
.maximize-screen {
  background: #3f4343;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  opacity: 1;
  border: none;
}
.setting-btn {
  background: rgba($color: #fff, $alpha: 0.3);
  width: 65px;
  height: 65px;
  border-radius: 50%;
  opacity: 1;
  border: none;
}
.video-btn {
  background: #6d787d;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  opacity: 1;
  border: none;
}
.square-btn {
  background: #464a4b;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  opacity: 1;
  border: none;
}
.square-btn i {
  color: #fff;
}
.camera {
  background: #748085;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  opacity: 1;
  border: none;
}
.mute i {
  color: #fff;
  font-size: 22px;
}
.leave-room span i {
  transform: rotate(90deg);
  font-size: 25px;
  color: #fff;
}
.parent-box-host {
  background: #edf1f4;
}
.appVideohome {
  flex-grow: 1;
}
.chatboxTogglebtn .parent-all-cam .parent-box-host .chatbox-toggle-btn {
  right: 47px !important;
  top: 75px !important;
  transform: rotate(180deg);
}
.multiUser {
  margin-left: 0px !important;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 90px;
  width: 279px !important;
  height: 190px;
  object-fit: cover;
  top: 90px;
  z-index: 9;
}
.home-main .container {
  padding: 0;
}

// chat box css
.tabs-btn {
  border-bottom: 1px solid #ededed;
  position: fixed;
  top: 0px;
  z-index: 9999;
  width: 33.3%;
  right: 0;
  background: #fff;
  z-index: 989;
}
.tabs-btn button {
  background: transparent;
  border: none;
  padding: 24px 0;
  color: #39394d;
  width: 50%;
  border-bottom: 3px solid transparent;
}
.tabs-btn button.active {
  color: #36b7bd;
  border-bottom: 3px solid #36b7bd;
}
.tabs-btn .btn:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.tabs-btn .btn.active:focus {
  border-bottom: 1px solid #000;
  border-radius: 0px;
}
.chatting-box .card {
  border: none;
}
.chat {
  list-style: none;
  margin: 0;
  padding: 0;
}
.participantsInfo {
  overflow-y: scroll;
  height: 95vh;
}
input[type="text"] {
  word-wrap: break-word;
  word-break: break-all;
}
ul {
  padding: 0px;
  margin: 0px;
}
li {
  list-style: none !important;
}
.chat-care {
  padding: 24px 0 0 0;
}
.chat-care .chat-img {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
}
.chat-img img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.chat-care .img-circle {
  border-radius: 50%;
}
.chat-care .chat-img {
  display: inline-block;
}
.chat-care .chat-body {
  display: inline-block;
  box-shadow: 0px 1px 4px #0000001a;
  background: #fff;
  border-radius: 12.5px;
  padding: 15px 20px;
  word-wrap: break-word !important;
  max-width: 75%;
}
.msgOption.dropdown-toggle:after {
  display: none !important;
}

textarea#chatMessage {
  padding-left: 60px;
  padding-right: 75px;
  height: 70px;
  padding-top: 20px;
}

li.agent {
  margin-bottom: 20px;
}
.chatTime {
  margin-right: 20px;
}
li.admin {
  margin-bottom: 20px;
  position: relative;
}
.chat-care .admin .chat-body {
  background: #d0d3e3;
  margin-right: 20px;
}
.chat-body {
  position: relative;
}
.chat-care .chat-body strong {
  color: #0169da;
}
.admin .chat-body p,
.agent .chat-body p {
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #081333;
  white-space: pre-line;
}
strong.primary-font {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #081333;
  margin-bottom: 6px;
  display: inline-block;
}
.clearfix {
  clear: both;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // background-color: #555;
}
.agent .chat-body .triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 0px solid transparent;
  box-shadow: 0px 1px 0px #0000001a;
  border-bottom: 50px solid #fff;
  position: absolute;
  left: -20px;
  bottom: 0;
}
.btn-send {
  color: #36b7bd;
  background: #36b7bd 0% 0% no-repeat padding-box;
  border-radius: 14px;
}
.btn-send img {
  width: 20px;
}
.btn-input {
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 12px !important;
  opacity: 1;
}
.card-footer:last-child {
  border-radius: 0px !important;
}

.card-footer {
  padding-top: 0.75rem;
  padding-bottom: 20px;
  background-color: #fff;
  border-top: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 33.3%;
}
button.btn-chat {
  position: absolute;
  cursor: pointer !important;
  right: 8px;
  top: 7px;
  background: #36b7bd 0% 0% no-repeat padding-box;
  border-radius: 14px;
  border: none;
  height: 56px;
  width: 56px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1090;
}
.area-g1 {
  overflow-x: hidden;
  height: 79vh;
  overflow-y: scroll;
}
.chat-care .input-group span {
  position: absolute;
  right: 17px;
  top: 7px;
}
.hostImg {
  width: 65px;
  height: 65px;
  background: #ccc;
  border: 1px solid #adadb7;
  border-radius: 17px;
  overflow: hidden;
}
.hostImg img {
  height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}
.participantsInfo .muteAll {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  padding: 10px 17px;
}
.participantsInfo .muteAll img {
  width: 22px;
}
.inviteParticipants.mut .chatbox-parent {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-top: 30px;
}
.hostInfo {
  padding: 10px 0;
  position: relative;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}
.hostInfo .hostImg {
  margin-right: 18px;
}
.hostInfo p {
  text-align: left;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #39394d;
  opacity: 1;
}
.hostInfo p small {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #36b7bd;
}
.hostInfo button {
  border: none;
  box-shadow: none;
  margin: 0 5px;
  background: transparent;
}
.hostInfo button:last-child {
  margin-right: 0px;
}
.userActionshow {
  display: block;
}
.userActionhide {
  display: none;
}
.removeMeeting {
  width: 273px;
}
.useractionDropdown li {
  color: #39394d;
  list-style: none;
  text-align: left;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
}
.useractionDropdown li:last-child {
  color: #fd5755;
  border: none;
}
.participantsInfo p {
  text-align: left;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0px;
  color: #081333;
}
.hostInfo .noResponse {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #fdd655 !important;
}
.chatTime small {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #081333 !important;
}
.inputfile {
  idth: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
}

.inputfile:focus + label {
  outline: none;
  outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
  pointer-events: none;
}
.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: none;
  outline: -webkit-focus-ring-color auto 5px;
}
.attachmentBtn {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1090;
}
.form-control:focus {
  color: #495057;
  background-color: transparent;
  border-color: #5eb2ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 115 221 / 25%);
}
.email-input .input-group {
  align-items: center;
}

.area-ig1 .chat-care {
  overflow: scroll;
  height: 83vh;
  padding: 0 10px 10px 10px;
  overflow-x: hidden;
}

#preview {
  background: transparent 0% 0% no-repeat padding-box;
  border: 4px solid #ffffff5e;
  border-radius: 17px;
  overflow: hidden;
  display: flex;
}

// .leave-room i.fa.fa-phone {  color: #fff;  transform: rotate(95deg);  font-size: 25px;}
// .dropdown-menu.callDropdown.show{border-radius:20px 10px 20px 0px}
// #user-dropdown:after{display: none;}
// .callDropdown button{padding: 0; margin: 0;}
// .callDropdown {text-align: left;padding: 20px;}
// .redBtn{color: #fd5755;}
// .callDropdown .dropdown-header{padding: 0;}
button.dropdown-item.redBtn {
  color: #fd5755 !important;
}
.dropup .dropdown-toggle::after {
  display: none !important;
}
.leave-room i.fa.fa-phone {
  color: #fff;
  transform: rotate(-264deg);
  font-size: 24px;
}
.callDropdown {
  width: 270px;
}
.callDropdown .dropdown-item:hover {
  background: transparent !important;
}
.callDropdown .dropdown-header {
  padding: 0;
}
.callDropdown button {
  padding-left: 30px;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
}
button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.dropdown-menu.callDropdown {
  border-radius: 20px 20px 20px 0px;
  text-align: left !important;
  left: 50px !important;
  top: 20px !important;
}
.subheading {
  margin-bottom: 80px;
}
.subheadingnew {
  margin-bottom: 55px;
}
// .preferecesModal {
//   max-width: 812px;
// }
.meeting_name {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  color: #39394d;
  line-height: 33px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.gridViewBtn.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.gridDropdown {
  position: absolute;
  right: 64px;
}

.gridDropdown .dropdown-menu {
  right: 0;
  left: auto !important;
}

.gridViewBtn {
  border: none;
  box-shadow: none;
}
.gridViewBtn:hover {
  background: transparent;
  color: #0073dd;
}

// #toast-container {
//   text-transform: capitalize;
// }
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background: transparent !important;
  color: #000;
  border: none !important;
}
.time-picker {
  input {
    height: 50px;
    font-weight: 600;
  }
  .ngb-tp-meridian {
    button {
      height: 50px;
      font-weight: 600;
    }
  }
}
.error-time {
  color: red;
}
.transTag {
  position: absolute;
  color: #55fd87;
  top: 45px;
  left: 40px;
  z-index: 999;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
}
.transperntModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 9999;
  display: none;
}
#videoTags h1 {
  text-align: center;
  font-size: 85px;
  display: inline-block;
  position: relative;
  background: #36b7bd;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  line-height: 150px;
  color: #fff;
}

#videoTags div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  background: #aaa;
  height: 90vh;
}
.notFound {
  text-align: center;
  color: #c1c1c1;
  font-size: 20px;
}

.transDiv div {
  height: 180px;
  border: 1px solid #fff;
}
.transDiv {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 190px;
  height: 130px;
}

.transGridDiv video .transGridDiv video {
  height: 90vh;
  border: 1px solid #fff;
}
.msgOption {
  display: none;
  cursor: pointer;
}
li.admin:hover .msgOption {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  padding: 0;
  color: #d0d3e3;
}

//tc new css
//switch
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e9f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #36b7bd;
}

input:focus + .slider {
  box-shadow: 0 0 1px #36b7bd;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//switchoff

//landing-page-title
.landing-page-title {
  padding: 8px 25px;
  min-height: 60px;
  box-shadow: 0 1px 0 #e5e9f2;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  h2 {
    font-size: 22px;
    color: #39394d;
    font-weight: 600;
  }
  a {
    color: #36b7bd;
    font-size: 16px;
    font-weight: 600;
    padding: 9px 28px;
    border: 1px solid #e5e9f2;
    border-radius: 7px;
    margin-left: 20px;
    display: inline-block;
    &:hover {
      background-color: #36b7bd;
      color: #fff;
      text-decoration: none;
    }
  }
}

// setup profile page
.setup-profile-header {
  //background-color: #39394d;
  background-color: #f1f3f9;
  position: sticky;
  top: 0;
  z-index: 1;
  img {
    width: 70px;
  }
}

.modal-dialog.modal-md-addcontact {
  max-width: 700px;
}

.modal-dialog.modal-md-invitePeople {
  max-width: 900px;
}

.modal-md-contactProfileModal {
  max-width: 550px;
}

.modal-md-contactProfileModal .modal-content {
  border-radius: 20px;
  background-color: #f9fafc;
  padding: 30px;
}

.modal-dialog.modal-md-editcontact {
  max-width: 750px;
}

// custom radio buttons
.gender-radio {
  .custom-radio {
    margin-right: 20px;
    label {
      padding-left: 35px;
      font-weight: 400 !important;
      font-size: 18px;
      cursor: pointer;
      margin-bottom: 0 !important;
    }
  }
}
//
.custom-radio [type="radio"]:checked,
.custom-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.custom-radio [type="radio"]:checked + label,
.custom-radio [type="radio"]:not(:checked) + label {
  position: relative;
  line-height: 1;
}
.custom-radio [type="radio"]:checked + label:before,
.custom-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #fff;
  transform: translateY(-50%);
}
.custom-radio [type="radio"]:checked + label:after,
.custom-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #36b7bd;
  position: absolute;
  top: 50%;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease;
  transform: translateY(-50%);
}
.custom-radio [type="radio"]:checked + label:before {
  border: 2px solid #36b7bd;
}
.custom-radio [type="radio"]:not(:checked) + label:before {
  border: 2px solid #c0ccda;
}
.custom-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
}
.custom-radio [type="radio"]:checked + label:after {
  opacity: 1;
}

// form submit btns
.form-submit-btns {
  margin-top: 40px;
  button {
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 600;
    box-shadow: none;
    border: none;
    width: 100%;
    &.save-btn {
      background-color: #36b7bd;
      color: #fff;
    }
    &.cancel-btn {
      color: #36b7bd;
    }
  }
}
::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: #f5f5f5 !important;
}

.modal-lg-login.signupModal .row.modalRow {
  margin-left: 0;
  margin-right: 0;
}

.user-profile-listing-wrapper .new-meeting-main h4.sec-heading {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.settingModal {
  max-width: 90%;
}

//Responsive css starts here
@media (min-width: 1366px) {
  .container-fluid {
    max-width: 1920px;
    padding: 0 75px;
  }
}
@media (min-width: 1367px) and (max-width: 1919px) {
  .container-fluid {
    padding: 0 80px;
  }
  .frontHeaderBtn {
    padding: 0 40px;
    width: auto;
  }
  .premiumTitle {
    font-size: 35px;
    line-height: 48px;
  }
  .homeLeftCover {
    padding-right: 100px;
  }
}
@media (max-width: 1366px) {
  .navbar-light .customColl .navbar-nav .nav-link {
    text-align: left;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    margin-right: 20px;
  }
  .skyblueBtn.frontHeaderBtn {
    font-size: 15px;
    font-weight: 600 !important;
    height: auto;
    padding: 0.375rem 0.75rem;
    width: 180px;
  }

  .sign-left-modal {
    padding: 45px;
  }
  .input-group .placeholder {
    font-size: 13px;
  }
}

@media (max-width: 1280px) {
  .sign-left-modal {
    padding: 40px;
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .meeting_name {
    font-size: 20px;
  }
  .transTag {
    top: 25px;
    left: 10px;
  }

  .dashmidSubCover {
    margin-right: 190px;
    padding: 15px;
    margin-top: 145px;
    margin-left: 120px;
  }
  .dashmidCover {
    width: calc(100% - 500px);
  }
  .dashmidCover .skyblueBtn {
    height: 55px;
    font-size: 20px;
  }
  .dashRightCover {
    width: 500px;
  }
  .newMeetingBtn {
    height: 55px;
    font-size: 20px;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .videoImg {
    width: 26px;
  }
  .premiumPara {
    font-size: 19px;
    line-height: 30px;
    margin-top: 20px;
  }
  .videoImg {
    width: 23px;
  }
  .newMeetingBig {
    font-size: 20px;
    height: 55px;
    margin-top: 70px;
  }
  .premiumTitle {
    font-size: 35px;
    line-height: 48px;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    font-size: 17px;
    margin-right: 30px;
  }
  .navbar-brand img {
    max-width: 115px;
  }
  .frontHeaderBtn {
    font-size: 17px;
    height: 50px;
  }
  .customNavbar {
    height: 80px;
  }
  .container-fluid {
    padding: 0 70px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    font-size: 17px;
  }
  .homeCover {
    margin-bottom: 40px;
    margin-top: 80px;
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .btns-nav button img {
    width: 16px;
  }
  .leave-room i.fa.fa-phone {
    font-size: 16px;
  }
  .leave-room {
    line-height: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .transGridDiv {
    width: 100%;
    height: 90vh;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    font-size: 15px;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    line-height: 25px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    margin-right: 20px;
  }
  .frontHeaderBtn {
    font-size: 15px;
    height: 38px;
  }
  .container-fluid {
    padding: 0 75px;
  }
  .homeLeftCover {
    padding-right: 120px;
  }
  .premiumTitle {
    font-size: 26px;
    line-height: 38px;
    margin-top: 10px;
  }
  .premiumPara {
    font-size: 15px;
    line-height: 26px;
    margin-top: 20px;
  }
  .newMeetingBig {
    font-size: 15px;
    height: 45px;
    margin-top: 50px;
  }
  .homeCover {
    margin-top: 50px;
  }
  .videoImg {
    width: 18px;
  }
  .dashmidSubCover {
    margin-left: 100px;
    margin-right: 130px;
    margin-top: 100px;
    padding: 15px;
  }
  .dashmidCover .skyblueBtn,
  .dashmidCover .newMeetingBtn {
    font-size: 15px;
    height: 45px;
  }
  .dashRightCover {
    width: 480px;
  }
  .dashRightSubCover {
    width: calc(100% - 40px);
    height: calc(100% - 20%);
    overflow-y: scroll;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .transDiv {
    bottom: 140px;
  }
  .transTag {
    top: 30px;
    left: 10px;
  }
  .meeting_name {
    font-size: 18px;
  }
  .appHome {
    height: 100%;
  }
  .dashRightSubCover {
    width: calc(100% - 40px);
    height: calc(100% - 20%);
    overflow-y: scroll;
  }
  .multiUser {
    right: 53px;
    width: 80px !important;
    height: 120px !important;
  }
  .partName {
    font-size: 12px;
    top: 10px;
    left: 10px;
  }

  //responsive for for 4 users
  .participents-box {
    height: 90vh;
    // column-count: 2;
    // grid-template-columns: auto auto;
    column-gap: 10px;
    display: grid;
    grid-gap: 0px;
    // grid-template-rows: 25vh 25vh;
  }
  .participents-box div {
    height: auto;
  }
  // .participents-box div video {
  //   height: 40vh;
  // }
  .multiUser {
    right: 70px;
    width: 80px !important;
    height: 120px !important;
    top: 60px;
  }
  .moreThenTwo .participents-box video {
    height: 100%;
  }
  #preview {
    border-radius: 5px;
  }

  .leave-room {
    line-height: 40px;
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .mainRightCover {
    display: table;
  }
  .dashmidCover {
    display: table-cell;
    vertical-align: top;
  }
  .dashmidSubCover {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 160px;
  }
  .dashRightCover {
    display: table-cell;
    width: 400px;
  }
  .dashmidCover {
    width: calc(100% - 400px);
  }
  .signupModal.modal-dialog {
    max-width: 90% !important;
  }
  .homeLeftCover {
    padding-right: 70px;
  }
  .premiumTitle {
    font-size: 24px;
    line-height: 32px;
    margin-top: 10px;
  }
  .premiumPara {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }
  .newMeetingBig {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600 !important;
    height: 45px;
    margin-top: 40px;
    width: 100%;
  }
  .homeCover {
    margin-top: 50px;
  }
  .homeLeftCover {
    padding-right: 70px;
  }
  .videoImg {
    width: 18px;
  }
  .dashboardCover {
    display: table;
    width: 100%;
    height: calc(100% - 70px);
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .metting-btns-main .popover-header,
  .metting-btns-main .popover-body {
    font: normal normal 600 18px/25px Poppins;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  textarea#chatMessage {
    line-height: 30px;
  }
  .transGridDiv {
    width: 100%;
    height: 40vh;
  }
  .transGridDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .transGridDiv video {
    height: 40vh;
  }

  .transDiv {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 150px;
    height: 100px;
  }

  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
    /* position: absolute; */
  }

  .mainVideoBox {
    margin-top: 15px;
  }
  .translatorWith video {
    height: 40vh;
  }

  .meeting_name {
    font-size: 12px;
    top: 0;
  }
  // .participents-box video {
  //   height: 38vh;
  //   top: 20px;
  // }
  .partName {
    font-size: 10px;
    left: 10px;
    top: 0px;
  }
  .transTag {
    top: 20px;
    left: 10px;
  }
  .participents-box {
    display: grid;
    grid-gap: 0px;
    position: relative;

    height: 40vh;
    position: relative;
  }
  .moreThenTwo .participents-box {
    // grid-template-rows: 25vh 25vh 25vh;
  }
  .participents-box div {
    height: auto;
  }

  .multiUser {
    right: 53px;
    width: 80px !important;
    height: auto;
    top: 40px;
  }
  #videoTags div {
    height: 40vh;
  }
  .moreThenTwo .participents-box video {
    height: 100%;
  }
  #preview {
    border-radius: 5px;
  }

  .parent-all-cam {
    height: 50vh;
  }
  .parent-box-host .singleUser #preview video {
    height: 40vh;
  }
  .unmute,
  .maximize-screen,
  .square-btn,
  .camera,
  .mute {
    width: 32px;
    height: 32px;
  }
  .parent-box-host {
    padding: 15px 40px 80px 40px;
  }
  .btns-nav {
    bottom: 5px;
  }

  .btns-nav button img {
    width: 18px;
    height: 18px;
  }
  .leave-room {
    line-height: 40px;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .navbar-light .customColl .navbar-nav .nav-item:last-child a {
    margin-right: 0px;
    padding: 0;
  }

  .sign-left-modal {
    order: 1;
    padding: 20px;
  }
  .homeLeftCover button.skyblueBtn {
    margin-top: 40px;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    line-height: 25px;
  }
  .premiumPara {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }
  .homeCover {
    margin-top: 30px;
  }
  .homeLeftCover {
    float: none;
    margin: 0 auto;
    max-width: 420px;
    padding-right: 0;
    text-align: center;
  }
  .premiumTitle {
    font-size: 23px;
    line-height: 33px;
  }
  .container-fluid {
    padding: 0 40px;
  }
  .homeLeftCover {
    padding-right: 0px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    font-size: 15px;
    line-height: 25px;
    line-height: 27px;
  }
  .customdashLink li.nav-item {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboardCover {
    padding-right: 0;
    height: calc(100% - 70px);
  }
  .dashmidCover {
    display: inline-block;
    width: 100%;
  }
  .dashRightCover {
    display: inline-block;
    padding: 0px 40px 40px 40px;
    width: 100%;
  }
  .caller_Meeting_Cover .singleVideoCover {
    width: 100%;
  }
  .chatTabCover {
    margin-bottom: 20px;
    width: 100%;
  }
  .singlVideoImg {
    height: calc(50vh - 92px);
  }
  .chatMsgCover {
    height: calc(50vh - 105px);
  }
  .doubleVideo .singlVideoImg {
    height: 35vh;
  }
  .allControllCover .chevronRight {
    right: 10px;
  }
  .chevronRight {
    right: 0;
  }
  .hostUserDetail {
    font-size: 13px;
  }
  .dashmidSubCover {
    margin-left: 40px;
  }
  .dashRightSubCover {
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
    margin-bottom: 0;
  }
  .chatmidCover {
    box-shadow: 0px 3px 6px #00000029;
    display: inline-block;
    width: 100%;
  }
  .chatRightCover {
    display: inline-block;
    margin-top: 5px;
    width: 100%;
  }
  .chatRightCover .chatMsgCover {
    height: calc(50vh - 225px);
  }
  .attendFormLeftCover,
  .attendFormRightCover {
    width: 50%;
  }
  .singleVideoDisscussCover {
    text-align: center;
  }
  .arrowTeerImg {
    float: left;
    margin-top: 3px;
  }
}
@media (min-width: 1600px) and (max-width: 1679px) {
  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .premiumTitle {
    font-size: 38px;
    line-height: 55px;
  }
  .premiumPara {
    font-size: 23px;
    line-height: 32px;
    margin-top: 20px;
  }
  .homeCover {
    margin-bottom: 40px;
    margin-top: 80px;
  }
  .frontHeaderBtn {
    font-size: 18px;
    height: 55px;
    width: 224px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    font-size: 18px;

    margin-right: 40px;
  }
  .customNavbar {
    height: 85px;
  }
  .navbar-brand img {
    max-width: 124px;
  }
  .newMeetingBig {
    font-size: 22px;
    height: 65px;
    margin-top: 70px;
  }
}

@media (min-width: 1919px) {
  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .meeting_name {
    font-size: 22px;
    top: 0px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dashRightSubCover {
    width: calc(100% - 40px);
    height: calc(100% - 20%);
    overflow-y: scroll;
  }
  // .partName {
  //   left: 10px;
  //   top: 4px;
  // }
  #preview {
    border-radius: 7px;
  }
  .multiUser {
    border-radius: 5px;
    right: 70px;
    width: 80px !important;
    top: 60px;
  }
  .area-ig1 .chat-care {
    overflow: scroll;
    height: 89vh;
    padding: 0 10px 20px 10px;
    overflow-x: hidden;
    padding-top: 70px;
  }

  .modal-lg-login.signupModal {
    max-width: 1457px !important;
  }
  //header css above 1929
  .user-img > img {
    height: 40px;
    width: 40px;
  }
  .container-fluid {
    padding: 0 100px;
  }
  .premiumTitle {
    font-size: 42px;
    line-height: 63px;
  }
  .premiumPara {
    font-size: 26px;
    line-height: 39px;
    margin-top: 20px;
  }
  .newMeetingBig {
    margin-top: 70px;
  }
  .homeLeftCover button.skyblueBtn {
    font-size: 24px;
  }
  .homeLeftCover {
    padding-right: 60px;
  }
  .homeCover {
    margin-top: 100px;
  }
  .customNavbar {
    height: 100px;
  }
  .navbar-brand img {
    max-width: 154px;
  }
  .frontHeaderBtn {
    padding: 0 45px;
  }
  .orText {
    font-size: 26px;
    line-height: 39px;
  }
  .welcomDashTitle {
    font-size: 30px;
    line-height: 46px;
  }
  .container-fluid {
    padding: 0 100px;
  }
  .customNavbar {
    height: 100px;
  }
  .navbar-brand img {
    max-width: 154px;
  }
  .fronHeader.navbar .customColl .navbar-nav .nav-link {
    font-size: 20px;
    line-height: 45px;
  }
  .newMeetingBtn {
    height: 80px;
    font-size: 24px;
  }
  // .skyblueBtn,
  // .skyblueBtn:hover,
  // .skyblueBtn:focus {
  //   font-size: 20px;
  //   height: 60px;
  //   line-height: 30px;
  // }

  .skyblueBtn {
    font-size: 20px;
    height: 60px;
    line-height: 30px;
  }
  .homeCover {
    margin-bottom: 40px;
    margin-top: 100px;
  }
  .homeLeftCover {
    padding-right: 60px;
  }
  .premiumTitle {
    font-size: 42px;
    line-height: 63px;
  }
  .premiumPara {
    font-size: 26px;
    line-height: 39px;
  }
  .videoImg {
    width: 30px;
  }
  .homeLeftCover button.skyblueBtn {
    font-size: 24px;
    height: 80px;
  }
  .orText {
    font-size: 26px;
    line-height: 39px;
  }
  input.newMeetingBtn {
    font-size: 24px;
    height: 80px;
  }
  .signinUpText {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 40px;
  }
  .formLabelText {
    font-size: 17px;
    line-height: 23px;
  }
  .formCover .formInput,
  .formCover .formInput:hover,
  .formCover .formInput:focus,
  .formSelect,
  .formSelect:hover,
  .formSelect:focus {
    font-size: 17px;
    height: 50px;
    line-height: 23px;
  }
  .signupModal .modal-dialog {
    max-width: 1457px;
  }
  .signupLink {
    font-size: 17px;
    line-height: 40px;
  }
  .signinMainCover .formCol {
    padding: 40px 90px;
  }
  .logoImg {
    width: 154px;
  }
  .signinMainCover .formLogoImgCol {
    padding: 40px 0;
  }
  .logoWithVideoCover {
    padding: 0 40px;
  }
  .backLoginText {
    font-size: 17px;
    line-height: 40px;
  }
  .dashLeftImg {
    height: 24px;
    width: 22px;
  }
  .sideLink {
    font-size: 16px;
    line-height: 25px;
    margin-top: 10px;
  }
  .dashmidSubCover {
    margin-left: 150px;
    margin-right: 264px;
    margin-top: 160px;
    padding: 15px;
  }
  .dashmidCover .skyblueBtn,
  .dashmidCover .skyblueBtn:hover,
  .dashmidCover .skyblueBtn:focus {
    font-size: 24px;
    height: 80px;
  }
  .welcomDashTitle {
    color: #39394d;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
  }
  .dashmidCover {
    width: calc(100% - 620px);
  }
  .dashRightCover {
    width: 620px;
  }
  .dashRightSubCover {
    width: calc(100% - 100px);
  }
  .dashRightTitle {
    font-size: 25px;
  }
  .dayText {
    font-size: 20px;
  }
  .dashRightTitle {
    font-size: 25px;
  }
  .pinkTime,
  .purpleTime,
  .greenTime {
    border-radius: 17px;
    font-size: 20px;
    height: 70px;
    line-height: 70px;
    width: 70px;
  }
  .meetingTitle {
    font-size: 20px;
  }
  .meetingTime {
    float: left;
    font-size: 16px;
    width: 100%;
  }
  .hostTitle {
    float: left;
    font-size: 16px;
    width: 100%;
  }
  .meetingRight .skyblueBtn,
  .meetingRight .skyblueBtn:hover,
  .meetingRight .skyblueBtn:focus {
    font-size: 16px;
    height: 44px;
    width: 246px;
    line-height: 25px;
  }
  .leftMeetingTitle {
    font-size: 41px;
    line-height: 61px;
  }

  .videoHeaderCvoer {
    padding: 17px 40px;
  }
  .videoHeaderCvoer .meetingTitle {
    font-size: 22px;
  }
  .closeMiniMaxiCover a img {
    width: 12px;
  }
  .leftMeetingDetailCover {
    height: calc(100vh - 70px);
  }
  .chatTabCover ul.nav-tabs li.nav-item a.nav-link {
    font-size: 18px;
  }
  .hostUserImg {
    height: 65px;
    width: 65px;
  }
  .hostUserDetail {
    font-size: 16px;
    width: calc(100% - 65px);
  }
  .inviteBtn,
  .inviteBtn:hover,
  .inviteBtn:focus {
    font-size: 18px;
    height: 60px;
    width: 247px;
  }
  .startMeetHoverDetail {
    width: 529px;
  }
  .startMeetHoverDetail ul li {
    border-bottom: 1px solid #ededed;
  }
  .startMeetHoverDetail ul li a {
    padding: 20px;
  }
  .videoLinkImg {
    width: 30px;
  }
  .videoLinkText {
    font-size: 22px;
    padding-left: 35px;
    width: calc(100% - 30px);
  }
  .connectingModal .modal-dialog {
    max-width: 500px;
  }
  .connectingModal .modal-content {
    border-radius: 35px;
  }
  .connectingCover {
    margin: 80px 0;
  }
  .connectingCover .alertImg {
    max-width: 82px;
  }
  .connectingCover .alertImg img {
    width: 100%;
  }
  .connectingTitle {
    font-size: 34px;
    line-height: 51px;
    margin-top: 70px;
  }
  .connectingPara {
    font-size: 26px;
    line-height: 39px;
    margin-top: 40px;
  }
  .connectingCover .modalCancelBtn {
    margin-top: 40px;
  }
  .permissionModal .modal-dialog {
    max-width: 617px;
  }
  .newMeetingModal .modal-content {
    border-radius: 35px;
  }
  .newMeetingModal .modal-header {
    padding: 60px 60px 25px 60px;
  }
  .newMeetingTitle {
    font-size: 26px;
  }
  .preferencePara {
    font-size: 20px;
    margin-top: 10px;
  }
  .permissionModal .modal-body {
    padding: 10px 60px 40px 60px;
  }
  .permissionCover .permissionTileCover {
    margin-top: 78px;
  }
  .permissionTileImg {
    width: 39px;
  }
  .permissionTileImg img {
    width: 100%;
  }
  .permissionTileDetail {
    width: calc(100% - 39px);
  }
  .permissionTitle {
    font-size: 26px;
  }
  .permissionPara {
    font-size: 20px;
    margin-top: 12px;
  }
  .preferenceBtnCover {
    margin-top: 61px;
  }
  .loadingImg {
    max-width: 107px;
  }
  .preferencesModal .modal-dialog {
    max-width: 812px;
  }
  .preferencesModal .modal-body {
    padding: 10px 60px 40px 60px;
  }
  .formCheckCover .form-check {
    font-size: 20px;
  }
  .formCheckCover .form-check input {
    height: 20px;
    width: 20px;
  }
  .linkText {
    font-size: 20px;
    height: 64px;
    line-height: 64px;
  }
  .moreOptionText {
    margin-top: 57px;
  }
  .moreOptionText a,
  .moreOptionText a:hover {
    font-size: 20px;
  }
  .blackScreen {
    max-height: 500px;
    max-width: 800px;
  }
  .blackScreenMSGCover .callerName {
    font-size: 22px;
  }
  .chatCallerName {
    font-size: 12px;
  }
  .callerMsg {
    font-size: 16px;
    padding: 15px 25px;
  }
  .callerMsgImg {
    height: 40px;
    width: 40px;
  }
  .inputMsgSubCover input {
    font-size: 16px;
    height: 70px;
    width: calc(100% - 62px);
  }
  .sentImgCover {
    height: 56px;
    line-height: 56px;
    margin-right: 6px;
    margin-top: 6px;
    width: 56px;
  }
  .inputMsgSubCover.attachCover input {
    width: calc(100% - 91px);
  }
  .paperclipImgCover {
    margin-top: 23px;
  }
  .attendMeetingLinkCover {
    padding: 0 183px 0 0;
  }
  .attendMeetingTitle {
    font-size: 26px;
    margin-bottom: 6px;
  }
  .chatGroupImg {
    width: 55px;
  }
  .oneImg,
  .secondSpanImg {
    height: 40px;
    width: 40px;
  }
  .chatGroupMsg {
    width: calc(100% - 55px);
  }
  .groupChatName {
    font-size: 16px;
  }
  .groupChatTime {
    font-size: 12px;
  }
  .msgTitle {
    font-size: 24px;
  }
  .callerMsgCover {
    margin-top: 25px;
  }
  .phone {
    height: 83px;
    line-height: 83px;
    width: 83px;
  }
  img.phoneImg {
    width: 24px;
  }
  .maximize {
    height: 65px;
    line-height: 65px;
    width: 65px;
  }
  img.maximize {
    width: 18px;
  }
  .setting {
    height: 65px;
    line-height: 65px;
    width: 65px;
  }
  img.settingImg {
    width: 22px;
  }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  .transGridDiv {
    width: 100%;
    height: 90vh;
  }
  .dashRightSubCover {
    width: calc(100% - 40px);
    height: calc(100% - 20%);
    overflow-y: scroll;
  }
  .partName {
    font-size: 12px;
    left: 10px;
    top: 4px;
  }
  #preview {
    border-radius: 7px;
  }
  .multiUser {
    border-radius: 5px;
    right: 70px;
    width: 80px !important;
    top: 60px;
  }
  .tabs-btn button {
    font-size: 14px;
  }
  .actvParticipant,
  .otherParticipants {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .participantsInfo p {
    font-size: 14px;
  }
  .hostImg {
    width: 38px;
    height: 38px;
    border-radius: 7px;
  }
  .participantsInfo .muteAll {
    font-size: 14px;
  }
  .participantsInfo .muteAll img {
    width: 18px;
  }

  .newMeetingBig {
    font-size: 24px;
    height: 80px;
    margin-top: 70px;
  }
  .container-fluid {
    padding: 0 100px;
  }
  .customNavbar {
    height: 100px;
  }
  .navbar-brand img {
    max-width: 154px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    font-size: 20px;
    // line-height: 45px;
  }
  .frontHeaderBtn {
    line-height: 30px;
  }
  .homeCover {
    margin-bottom: 40px;
    margin-top: 100px;
  }
  .homeLeftCover {
    padding-right: 60px;
  }
  .premiumTitle {
    font-size: 42px;
    line-height: 63px;
  }
  .premiumPara {
    font-size: 26px;
    line-height: 39px;
    margin-top: 20px;
  }
  .videoImg {
    width: 30px;
  }
  .orText {
    font-size: 26px;
    line-height: 39px;
  }
  .newMeetingBtn {
    font-size: 24px;
    height: 80px;
  }
  .signinUpText {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 30px;
  }
  .formLabelText {
    font-size: 17px;
    line-height: 23px;
  }
  .formCover .formInput,
  .formCover .formInput:hover,
  .formCover .formInput:focus,
  .formSelect,
  .formSelect:hover,
  .formSelect:focus {
    font-size: 17px;
    height: 50px;
    line-height: 23px;
  }
  .signupModal .modal-dialog {
    max-width: 1200px;
  }
  .signupLink {
    font-size: 17px;
    line-height: 40px;
  }
  .signinMainCover .formCol {
    padding: 35px 55px;
  }
  .logoImg {
    width: 154px;
  }
  .signinMainCover .formLogoImgCol {
    padding: 40px 0;
  }
  .logoWithVideoCover {
    padding: 0 40px;
  }
  .backLoginText {
    font-size: 17px;
    line-height: 40px;
  }
  .dashLeftImg {
    height: 24px;
    width: 22px;
  }
  .sideLink {
    font-size: 16px;
    line-height: 25px;
    margin-top: 10px;
  }
  .dashmidSubCover {
    margin-left: 150px;
    margin-top: 160px;
  }
  .dashmidCover .skyblueBtn,
  .dashmidCover .skyblueBtn:hover,
  .dashmidCover .skyblueBtn:focus {
    font-size: 24px;
    height: 70px;
  }
  .dashmidCover .newMeetingBtn {
    height: 70px;
  }
  .welcomDashTitle {
    color: #39394d;
    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
  }
  .dashmidCover {
    width: calc(100% - 620px);
  }
  .dashRightCover {
    width: 620px;
  }
  .dashRightSubCover {
    width: calc(100% - 100px);
  }
  .dashRightTitle {
    font-size: 25px;
  }
  .dayText {
    font-size: 19px;
  }
  .dashRightTitle {
    font-size: 23px;
  }
  .pinkTime,
  .purpleTime,
  .greenTime {
    border-radius: 17px;
    font-size: 20px;
    height: 70px;
    line-height: 70px;
    width: 70px;
  }
  .meetingTitle {
    font-size: 19px;
  }
  .meetingTime {
    float: left;
    font-size: 16px;
    width: 100%;
  }
  .hostTitle {
    float: left;
    font-size: 16px;
    width: 100%;
  }
  .meetingRight .skyblueBtn,
  .meetingRight .skyblueBtn:hover,
  .meetingRight .skyblueBtn:focus {
    font-size: 16px;
    height: 44px;
  }
  .leftMeetingTitle {
    font-size: 41px;
    line-height: 61px;
  }

  .leftMeetingDetailCover {
    height: calc(100vh - 60px);
  }
  .chatTabCover ul.nav-tabs li.nav-item a.nav-link {
    font-size: 17px;
  }
  .hostUserImg {
    height: 55px;
    width: 55px;
  }
  .hostUserDetail {
    font-size: 15px;
    width: calc(100% - 55px);
  }
  .inviteBtn,
  .inviteBtn:hover,
  .inviteBtn:focus {
    font-size: 17px;
    height: 54px;
    width: 235px;
  }
  .startMeetHoverDetail {
    width: 450px;
  }
  .startMeetHoverDetail ul li {
    border-bottom: 1px solid #ededed;
  }
  .startMeetHoverDetail ul li a {
    padding: 20px;
  }
  .videoLinkImg {
    width: 25px;
  }
  .videoLinkText {
    font-size: 17px;
    padding-left: 25px;
    width: calc(100% - 25px);
  }
  .connectingModal .modal-dialog {
    max-width: 450px;
  }
  .connectingModal .modal-content {
    border-radius: 27px;
  }
  .connectingCover {
    margin: 70px 0;
  }
  .connectingCover .alertImg {
    max-width: 70px;
  }
  .connectingCover .alertImg img {
    width: 100%;
  }
  .connectingTitle {
    font-size: 28px;
    line-height: 45px;
    margin-top: 55px;
  }
  .connectingPara {
    font-size: 22px;
    line-height: 35px;
    margin-top: 30px;
  }
  .connectingCover .modalCancelBtn {
    margin-top: 30px;
  }
  .permissionModal .modal-dialog {
    max-width: 560px;
  }
  .newMeetingModal .modal-content {
    border-radius: 28px;
  }
  .newMeetingModal .modal-header {
    padding: 50px 50px 25px 50px;
  }
  .newMeetingTitle {
    font-size: 23px;
  }
  .preferencePara {
    font-size: 18px;
    margin-top: 7px;
  }
  .permissionModal .modal-body {
    padding: 10px 50px 35px 50px;
  }
  .permissionCover .permissionTileCover {
    margin-top: 60px;
  }
  .permissionTileImg {
    width: 30px;
  }
  .permissionTileImg img {
    width: 100%;
  }
  .permissionTileDetail {
    width: calc(100% - 30px);
  }
  .permissionTitle {
    font-size: 23px;
  }
  .permissionPara {
    font-size: 18px;
    margin-top: 12px;
  }
  .preferenceBtnCover {
    margin-top: 48px;
  }
  .loadingImg {
    max-width: 95px;
  }
  .preferencesModal .modal-dialog {
    max-width: 700px;
  }
  .preferencesModal .modal-body {
    padding: 10px 50px 30px 50px;
  }
  .formCheckCover .form-check {
    font-size: 17px;
  }
  .formCheckCover .form-check input {
    height: 17px;
    width: 17px;
  }
  .linkText {
    font-size: 18px;
    height: 55px;
    line-height: 55px;
  }
  .moreOptionText {
    margin-top: 50px;
  }
  .moreOptionText a,
  .moreOptionText a:hover {
    font-size: 18px;
  }
  .blackScreen {
    max-height: 460px;
    max-width: 740px;
  }
  .blackScreenMSGCover .callerName {
    font-size: 20px;
  }
  .chatCallerName {
    font-size: 12px;
  }
  .callerMsg {
    font-size: 16px;
    padding: 10px 20px;
  }
  .callerMsgImg {
    height: 38px;
    width: 38px;
  }
  .inputMsgSubCover input {
    font-size: 16px;
    height: 70px;
    width: calc(100% - 62px);
  }
  .sentImgCover {
    height: 56px;
    line-height: 56px;
    margin-right: 6px;
    margin-top: 6px;
    width: 56px;
  }
  .inputMsgSubCover.attachCover input {
    width: calc(100% - 91px);
  }
  .paperclipImgCover {
    margin-top: 23px;
  }
  .attendMeetingLinkCover {
    padding: 0 130px 0 0;
  }
  .attendMeetingTitle {
    font-size: 24px;
  }
  .chatGroupImg {
    width: 55px;
  }
  .oneImg,
  .secondSpanImg {
    height: 40px;
    width: 40px;
  }
  .chatGroupMsg {
    width: calc(100% - 55px);
  }
  .groupChatName {
    font-size: 16px;
  }
  .groupChatTime {
    font-size: 12px;
  }
  .msgTitle {
    font-size: 22px;
  }
  .callerMsgCover {
    margin-top: 22px;
  }
  .phone {
    height: 70px;
    line-height: 70px;
    width: 70px;
  }
  img.phoneImg {
    width: 24px;
  }
  .maximize {
    height: 55px;
    line-height: 55px;
    width: 55px;
  }
  img.maximize {
    width: 16px;
  }
  .setting {
    height: 55px;
    line-height: 55px;
    width: 55px;
  }
  img.settingImg {
    width: 20px;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .area-ig1 .chat-care {
    padding-top: 70px;
  }
}

@media (min-width: 1600px) and (max-width: 1679px) {
  .transGridDiv {
    width: 100%;
    height: 90vh;
  }
  .dashRightSubCover {
    width: calc(100% - 40px);
    height: calc(100% - 20%);
    overflow-y: scroll;
  }
  .partName {
    font-size: 12px;
    left: 10px;
    top: 4px;
  }
  #preview {
    border-radius: 7px;
  }
  .multiUser {
    border-radius: 5px;
    right: 70px;
    width: 80px !important;
    top: 60px;
  }
  .tabs-btn button {
    font-size: 14px;
  }
  .actvParticipant,
  .otherParticipants {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .participantsInfo p {
    font-size: 14px;
  }
  .hostImg {
    width: 38px;
    height: 38px;
    border-radius: 7px;
  }
  .participantsInfo .muteAll {
    font-size: 14px;
  }
  .participantsInfo .muteAll img {
    width: 18px;
  }

  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .container-fluid {
    padding: 0 80px;
  }
  .customNavbar {
    height: 85px;
  }
  .navbar-brand img {
    max-width: 124px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    font-size: 18px;
  }
  .customNavbar .navbar-nav .nav-item {
    padding-right: 40px;
  }
  // .skyblueBtn,
  // .skyblueBtn:hover,
  // .skyblueBtn:focus {
  //   font-size: 18px;
  //   height: 55px;
  //   width: 100%;
  // }
  .skyblueBtn {
    font-size: 18px;
    height: 55px;
    width: 100%;
  }
  .homeCover {
    margin-bottom: 40px;
    margin-top: 80px;
  }
  .homeLeftCover {
    padding-right: 100px;
  }
  .premiumTitle {
    font-size: 38px;
    line-height: 55px;
  }
  .premiumPara {
    font-size: 23px;
    line-height: 32px;
  }
  .videoImg {
    width: 26px;
  }
  .homeLeftCover button.skyblueBtn {
    font-size: 22px;
    height: 65px;
  }
  .orText {
    font-size: 22px;
    line-height: 34px;
  }
  .newMeetingBtn {
    font-size: 22px;
    height: 65px;
  }
  .signupModal .modal-dialog {
    max-width: 1200px;
  }
  .signupModal .modal-content {
    border: none;
    border-radius: 35px;
    // height: 88vh;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .signinUpText {
    font-size: 22px;
  }
  .formLabelText {
    font-size: 16px;
    line-height: 20px;
  }
  .formCover .formInput,
  .formCover .formInput:hover,
  .formCover .formInput:focus,
  .formSelect,
  .formSelect:hover,
  .formSelect:focus {
    font-size: 16px;
    height: 42px;
  }
  .signupLink {
    font-size: 16px;
  }
  .signinMainCover .formCol {
    padding: 40px 70px;
  }
  .logoImg {
    width: 140px;
  }
  .logoWithVideoCover {
    padding: 0 40px;
  }
  .dashLeftImg {
    height: 22px;
    width: 20px;
  }
  .sideLink {
    font-size: 15px;
    line-height: 24px;
    margin-top: 8px;
  }
  .dashmidSubCover {
    margin-left: 135px;
    margin-top: 160px;
  }
  .dashmidCover .skyblueBtn,
  .dashmidCover .skyblueBtn:hover,
  .dashmidCover .skyblueBtn:focus {
    font-size: 22px;
    height: 65px;
  }
  .welcomDashTitle {
    color: #39394d;
    font-size: 28px;
    font-weight: 600;
    line-height: 46px;
  }
  .dashmidCover {
    width: calc(100% - 550px);
  }
  .dashRightCover {
    width: 550px;
  }
  .dashRightSubCover {
    width: calc(100% - 100px);
  }
  .dashRightTitle {
    font-size: 25px;
  }
  .dayText {
    font-size: 18px;
  }
  .dashRightTitle {
    font-size: 21px;
  }
  .pinkTime,
  .purpleTime,
  .greenTime {
    border-radius: 13px;
    font-size: 18px;
    height: 65px;
    line-height: 65px;
    width: 65px;
  }
  .meetingTitle {
    font-size: 18px;
  }
  .meetingTime {
    float: left;
    font-size: 15px;
    margin-top: 7px;
    width: 100%;
  }
  .hostTitle {
    float: left;
    font-size: 15px;
    margin-top: 7px;
    width: 100%;
  }
  .meetingRight .skyblueBtn,
  .meetingRight .skyblueBtn:hover,
  .meetingRight .skyblueBtn:focus {
    font-size: 16px;
    height: 44px;
  }
  .leftMeetingTitle {
    font-size: 38px;
    line-height: 55px;
  }

  .leftMeetingDetailCover {
    height: calc(100vh - 60px);
  }
  .chatTabCover ul.nav-tabs li.nav-item a.nav-link {
    font-size: 16px;
  }
  .hostUserImg {
    height: 50px;
    width: 50px;
  }
  .hostUserDetail {
    font-size: 15px;
    width: calc(100% - 50px);
  }
  .inviteBtn,
  .inviteBtn:hover,
  .inviteBtn:focus {
    font-size: 16px;
    height: 48px;
    width: 220px;
  }
  .startMeetHoverDetail {
    width: 400px;
  }
  .startMeetHoverDetail ul li a {
    padding: 17px;
  }
  .videoLinkImg {
    width: 22px;
  }
  .videoLinkText {
    font-size: 16px;
    padding-left: 20px;
    width: calc(100% - 22px);
  }
  .connectingModal .modal-dialog {
    max-width: 400px;
  }
  .connectingModal .modal-content {
    border-radius: 22px;
  }
  .connectingCover {
    margin: 60px 0;
  }
  .connectingCover .alertImg {
    max-width: 60px;
  }
  .connectingCover .alertImg img {
    width: 100%;
  }
  .connectingTitle {
    font-size: 24px;
    line-height: 40px;
    margin-top: 50px;
  }
  .connectingPara {
    font-size: 19px;
    line-height: 32px;
    margin-top: 24px;
  }
  .connectingCover .modalCancelBtn {
    margin-top: 24px;
  }
  .permissionModal .modal-dialog {
    max-width: 500px;
  }
  .newMeetingModal .modal-content {
    border-radius: 22px;
  }
  .newMeetingModal .modal-header {
    padding: 40px 40px 25px 40px;
  }
  .newMeetingTitle {
    font-size: 20px;
  }
  .preferencePara {
    font-size: 16px;
    margin-top: 6px;
  }
  .permissionModal .modal-body {
    padding: 10px 40px 28px 40px;
  }
  .permissionCover .permissionTileCover {
    margin-top: 50px;
  }
  .permissionTileImg {
    width: 26px;
  }
  .permissionTileImg img {
    width: 100%;
  }
  .permissionTileDetail {
    width: calc(100% - 26px);
  }
  .permissionTitle {
    font-size: 20px;
  }
  .permissionPara {
    font-size: 16px;
    margin-top: 8px;
  }
  .preferenceBtnCover {
    margin-top: 40px;
  }
  .loadingImg {
    max-width: 80px;
  }
  .preferencesModal .modal-dialog {
    max-width: 600px;
  }
  .preferencesModal .modal-body {
    padding: 10px 40px 20px 40px;
  }
  .formCheckCover .form-check {
    font-size: 16px;
  }
  .formCheckCover .form-check input {
    height: 16px;
    width: 16px;
  }
  .linkText {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
  }
  .moreOptionText {
    margin-top: 40px;
  }
  .moreOptionText a,
  .moreOptionText a:hover {
    font-size: 16px;
  }
  .blackScreen {
    max-height: 420px;
    max-width: 680px;
  }
  .blackScreenMSGCover .callerName {
    font-size: 19px;
  }
  .chatCallerName {
    font-size: 12px;
  }
  .callerMsg {
    font-size: 15px;
    padding: 10px 18px;
  }
  .callerMsgImg {
    height: 38px;
    width: 38px;
  }
  .inputMsgSubCover input {
    font-size: 15px;
    height: 60px;
    width: calc(100% - 55px);
  }
  .sentImgCover {
    height: 50px;
    line-height: 50px;
    margin-right: 5px;
    margin-top: 5px;
    width: 50px;
  }
  .inputMsgSubCover.attachCover input {
    width: calc(100% - 84px);
  }
  .paperclipImgCover {
    margin-top: 17px;
  }
  .attendMeetingLinkCover {
    padding: 0 120px 0 0;
  }
  .attendMeetingTitle {
    font-size: 22px;
  }
  .msgTitle {
    font-size: 20px;
  }
  .callerMsgCover {
    margin-top: 20px;
  }
  .hostRightCover {
    margin-top: 7px;
  }
  .phone {
    height: 60px;
    line-height: 60px;
    width: 60px;
  }
  img.phoneImg {
    width: 22px;
  }
  .maximize {
    height: 45px;
    line-height: 45px;
    width: 45px;
  }
  img.maximize {
    width: 15px;
  }
  .setting {
    height: 45px;
    line-height: 45px;
    width: 45px;
  }
  img.settingImg {
    width: 18px;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .area-ig1 .chat-care {
    padding-top: 70px;
  }
}
//
@media (min-width: 1400px) and (max-width: 1599px) {
  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .transGridDiv {
    width: 100%;
    height: 90vh;
  }
  .dashRightSubCover {
    width: calc(100% - 40px);
    height: calc(100% - 20%);
    overflow-y: scroll;
  }
  .partName {
    font-size: 14px;
    left: 10px;
    top: 4px;
  }
  #preview {
    border-radius: 7px;
  }
  .multiUser {
    border-radius: 5px;
    right: 70px;
    width: 80px !important;
    top: 60px;
  }

  .tabs-btn button {
    font-size: 14px;
  }
  .actvParticipant,
  .otherParticipants {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .participantsInfo p {
    font-size: 14px;
  }
  .hostImg {
    width: 38px;
    height: 38px;
    border-radius: 7px;
  }
  .participantsInfo .muteAll {
    font-size: 14px;
  }
  .participantsInfo .muteAll img {
    width: 18px;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .arledy-signup-txt a {
    font-size: 14px;
  }

  .signUp-main .input-group select {
    height: 32px;
    border-radius: 5px;
    font-size: 14px;
  }
  .signUp-main .input-group input {
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
  }
  .arledy-signup-txt {
    font-size: 14px;
  }
  .signUp-main label {
    font-size: 14px;
    line-height: 18px;
  }
  .signup-prvcy-txt a {
    font-size: 14px;
    text-align: center;
  }
  .sign-up-btn {
    font-size: 15px;
    height: auto;
  }
  .arledy-signup-txt {
    font-size: 14px;
  }
  .signUp-main .input-group img {
    top: 9px;
  }
  .signup-prvcy-txt {
    font-size: 14px;
  }
  .area-ig1 .chat-care {
    padding-top: 70px;
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .transDiv {
    bottom: 140px;
  }
  .unmute,
  .maximize-screen,
  .leave-room,
  .square-btn,
  .camera,
  .mute {
    width: 40px;
    height: 40px;
  }
  .transTag {
    top: 25px;
    left: 10px;
    font-size: 14px;
  }
  .meeting_name {
    font-size: 20px;
  }

  .area-ig1 .chat-care {
    padding-top: 70px;
  }

  .partName {
    font-size: 14px;
    left: 10px;
    top: 4px;
  }
  #preview {
    border-radius: 7px;
  }
  .multiUser {
    border-radius: 5px;
    right: 70px;
    width: 80px !important;
    top: 60px;
  }

  .tabs-btn button {
    font-size: 14px;
  }
  .actvParticipant,
  .otherParticipants {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .participantsInfo p {
    font-size: 14px;
  }
  .hostImg {
    width: 38px;
    height: 38px;
    border-radius: 7px;
  }
  .participantsInfo .muteAll {
    font-size: 14px;
  }
  .participantsInfo .muteAll img {
    width: 18px;
  }

  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .dashmidCover {
    width: calc(100% - 480px);
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .arledy-signup-txt a {
    font-size: 13px;
  }

  .signUp-main .input-group select {
    height: 32px;
    border-radius: 5px;
    font-size: 14px;
  }
  .signUp-main .input-group input {
    font-size: 13px;
    height: 32px;
    border-radius: 5px;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .signUp-main label {
    font-size: 13px;
    line-height: 18px;
  }
  .signup-prvcy-txt a {
    font-size: 13px;
    text-align: center;
  }
  .sign-up-btn {
    font-size: 15px;
    height: auto;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .signUp-main .input-group img {
    top: 9px;
  }
  .signup-prvcy-txt {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  li.admin:hover .msgOption {
    right: 5px;
  }
  .area-ig1 .chat-care {
    padding-top: 70px;
  }
  .leave-room i.fa.fa-phone {
    font-size: 18px;
  }
  // fonts size till 1200px same
  .welcomDashTitle {
    font-size: 22px;
  }
  .dashRightTitle {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .setting-btn {
    position: relative;
    left: -100px;
  }

  .gridViewBtn {
    font-size: 14px;
    font-weight: 400;
  }
  // .btns-nav .setting-btn{display: none;}
  // .btns-nav-one .setting-btn{display: block;}
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .transDiv div {
    height: 100%;
    border: 1px solid #fff;
  }
  .transGridDiv {
    width: 100%;
    height: 90vh;
  }
  .setting-btn {
    width: 40px;
    height: 40px;
  }
  .unmute,
  .mute,
  .maximize-screen,
  .square-btn,
  .camera {
    width: 40px;
    height: 40px;
  }
  .btns-nav button img {
    width: 18px;
    height: 18px;
  }
  .participantsInfo {
    height: 94vh;
  }
  .participantsInfo .dropdown-menu.dropdown-menu-right.show {
    width: 200px;
  }
  .hostInfo button {
    font-size: 13px;
  }

  .participantsInfo p {
    font-size: 14px;
  }
  .hostInfo {
    padding-left: 10px;
    padding-right: 10px;
  }
  .hostImg {
    width: 45px;
    height: 45px;
    border-radius: 10px;
  }
  .actvParticipant,
  .otherParticipants {
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .participantsInfo .muteAll {
    padding: 5px 6px;
    font-size: 12px;
  }
  .participantsInfo .muteAll img {
    width: 16px;
  }
  .tabs-btn button {
    padding: 14px 0;
    font-size: 14px;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .arledy-signup-txt a {
    font-size: 13px;
  }
  .signup-prvcy-txt {
    font-size: 13px;
  }
  .meetingRight .skyblueBtn {
    font-size: 15px;
    height: 44px;
    line-height: 1.5;
    width: 180px;
  }
  .dashmidCover .skyblueBtn {
    font-size: 15px;
    font-weight: 600;
    height: 45px;
    margin-top: 30px;
  }
  .newMeetingBtn {
    font-size: 15px;
    height: 45px;
  }
  .signUp-main .input-group select {
    height: 32px;
    font-size: 14px;
  }

  .videoImg {
    width: 18px;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .container-fluid {
    padding: 0 50px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    margin-right: 20px;
  }
  .homeLeftCover {
    padding-right: 70px;
  }
  .homeCover {
    margin-top: 50px;
  }
  .premiumTitle {
    font-size: 24px;
    line-height: 32px;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    line-height: 25px;
  }
  .hostUserDetail {
    font-size: 13px;
  }
  .chatHeadTileCvoer {
    width: calc(100% - 160px);
  }
  .chatHeadContactCover {
    width: 160px;
  }
  .chatHeadContactCover ul li {
    padding: 0 5px;
  }
  .singleVideoDisscussCover {
    text-align: center;
  }
  .arrowTeerImg {
    float: left;
    margin-top: 3px;
  }
  .signUp-main .input-group input {
    font-size: 13px;
    height: 32px;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .signUp-main label {
    font-size: 13px;
    line-height: 18px;
  }
  .signup-prvcy-txt a {
    font-size: 13px;
    text-align: center;
  }
  .sign-up-btn {
    font-size: 15px;
    height: auto;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .signUp-main .input-group img {
    top: 9px;
  }
}

@media only screen and (min-width: 1023px) {
  .transGridDiv video,
  .transGridDiv video {
    border: 1px solid #fff;
    width: 100%;
    height: 90vh;
    object-fit: cover;
  }

  .center-user-content.container-fluid {
    padding: 0 15px !important;
    max-width: 100%;
  }
  .mainRightCover {
    display: flex;
    justify-content: space-evenly;
  }
  app-sidebar.appSidebar {
    display: table-cell;
    vertical-align: top;
  }
}
@media (min-width: 991px) and (max-width: 1023px) {
  .sign-right-modal {
    border-top-right-radius: 0px;
  }
}

@media (max-width: 1023px) {
  .mobileviewChat {
    display: block !important;
    width: 100%;
  }

  .home-main .container {
    max-width: 100%;
  }
  .col-md-12.col-lg-9.mobileVideo.p-0,
  .col-md-12.col-lg-3.p-0.mobileChat {
    width: 100% !important;
    max-width: 100%;
    flex: 0 0 100%;
    transition: all 2s;
  }
  .btns-nav-one {
    bottom: 10px;
  }
  .center-user-content.container-fluid {
    padding: 0px 15px !important;
  }
  .setting-btn {
    width: 40px;
    height: 40px;
  }
  .btns-nav-one button img {
    width: 18px;
  }
  .actvParticipant,
  .otherParticipants {
    margin-top: 0;
  }
  .modal-lg-login.signupModal {
    max-width: 90% !important;
  }
  .arledy-signup-txt a {
    font-size: 13px;
  }
  .sign-right-modal {
    border-bottom-right-radius: 0px;
  }

  .signUp-main .input-group select {
    height: 32px;
    border-radius: 5px;
    font-size: 14px;
  }
  .signUp-main .input-group input {
    font-size: 13px;
    height: 32px;
    border-radius: 5px;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .signUp-main label {
    font-size: 13px;
    line-height: 18px;
  }
  .signup-prvcy-txt a {
    font-size: 13px;
    text-align: center;
  }
  .sign-up-btn {
    font-size: 15px;
    height: auto;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .signUp-main .input-group img {
    top: 9px;
  }
  .signup-prvcy-txt {
    font-size: 13px;
  }

  .container-fluid {
    padding: 0 40px !important;
  }
  .participantsInfo p {
    font-size: 14px;
  }
  .participantsInfo .muteAll {
    font-size: 13px;
  }
  .participantsInfo .muteAll img {
    width: 18px;
  }
  .participantsInfo {
    padding: 0 !important;
    margin: 20px 0 !important;
    height: 42vh;
    overflow-y: scroll;
  }
  .sign-right-modal {
    border-top-left-radius: 35px;
    padding: 30px 15px;
    border-bottom-left-radius: 35px;
  }
  .signupModal.modal-dialog {
    max-width: 90% !important;
  }
  // .sign-left-modal {
  //   padding: 10px;
  // }
  .signUp-main label {
    font-size: 13px;
    line-height: 18px;
  }
  // .appSidebar {
  //   display: table-cell;
  //   vertical-align: top;
  // }
  .appHome {
    display: table-cell;
    justify-content: space-between;
    vertical-align: top;
  }
  .appHome {
    width: 100%;
  }
  .mainRightCover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .dashmidCover .skyblueBtn,
  .newMeetingBtn {
    font-size: 15px;
    font-weight: 600;
    height: 45px;
    margin-top: 0px;
  }
  .dashmidCover .skyblueBtn {
    margin-top: 30px;
  }
  .dashmidCover {
    width: 100%;
  }
  .dashRightCover {
    display: flex;
  }
  .dashRightUlCover {
    padding: 0 15px;
  }
  .dashLeftCover {
    background-color: #f1f3f9;
    display: table-cell;
    vertical-align: top;
    width: 100px;
  }
  .meetingRight .skyblueBtn {
    margin-top: 10px;
    text-transform: capitalize;
    height: 44px;
    font-size: 16px;
    padding: 0 20px;
  }
  .meetingTime {
    font-size: 13px;
    margin-top: 7px;
  }
  .hostTitle {
    font-size: 12px;
    margin-top: 7px;
  }
  .appSidebar {
    display: flex;
  }
  // .dashboardCover{display: table;vertical-align: top;}
  .dayText {
    font-size: 14px;
  }
  .dashRightUlCover ul li {
    padding-bottom: 20px;
    padding-top: 0;
  }
  .pinkTime,
  .purpleTime,
  .greenTime {
    font-size: 15px;
    height: 55px;
    width: 55px;
  }
  .newMeetingBig {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600 !important;
    height: 45px;
    margin-top: 30px;
    width: 100%;
  }
  .homeCover {
    margin-top: 30px;
  }
  .homeLeftCover {
    float: none;
    margin: 0 auto;
    max-width: 420px;
    padding-right: 0;
    text-align: center;
  }
  .premiumTitle {
    font-size: 23px;
    line-height: 33px;
  }
  .premiumPara {
    font-size: 14px;
    line-height: 24px;
  }
  .newMeetingBig {
    margin-bottom: 20px;
  }
  .premiumPara {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }
  .videoImg {
    margin-right: 10px;
    margin-top: -2px;
    width: 18px;
  }
  .skyblueBtn.frontHeaderBtn {
    font-size: 15px;
    font-weight: 600 !important;
    height: auto;
    padding: 0.375rem 0.75rem;
    width: 180px;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    margin-right: 20px;
    margin-top: 0px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    text-align: left;
  }

  button.dropdown-item.redBtn {
    color: #fd5755 !important;
  }
  .chatbox-toggle-btn {
    display: none;
  }
  .metting-btns-main .popover-header,
  .metting-btns-main .popover-body {
    font: normal normal 600 16px/23px Poppins;
  }
  .tabs-btn {
    position: static !important;
    width: 100% !important;
  }
  .card-body.chat-care {
    width: 100% !important;
    padding: 10px 0;
  }
  .area-ig1 .chat-care {
    overflow: scroll;
    height: 37vh;
    padding: 0 0 50px 0;
    overflow-x: hidden;
  }
  .chatbox-parent {
    overflow: hidden;
    height: calc(50vh - 0px);
  }
  button.btn-chat {
    border-radius: 7px;
    height: 35px;
    width: 40px;
  }
  button.btn-chat {
    position: absolute;
    cursor: pointer !important;
    right: 5px;
    top: 5px;
  }
  .btn-input {
    border-radius: 8px !important;
    height: 45px !important;
  }
  .attachmentBtn {
    left: 15px;
  }
  .signUp-main {
    padding: 20px;
  }
  .dashRightCover {
    width: 100%;
  }
  .dashmidSubCover {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    padding: 0;
  }
  .tabs-btn button {
    padding: 15px 0;
  }
  .card-footer {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .customNavbar {
    height: auto !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  textarea#chatMessage {
    line-height: 30px;
  }
  .leave-room {
    padding: 0;
  }
  .leave-room i.fa.fa-phone {
    font-size: 18px;
  }
  app-root {
    height: calc(100% - 70px);
  }
  // body {
  //   overflow: hidden;
  // }
  .dashboardCover {
    height: 100%;
  }
  //user more than 2 meeting
  .partName {
    font-size: 8px;
    left: 5px;
    top: 0;
  }

  .participents-box div {
    width: 100%;
  }
  .parent-box-host {
    padding: 15px;
  }
  .mainVideoBox {
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  }
  .participents-box {
    // display: flex;
    height: 50vh;
    grid-gap: 5px;
  }
  // .participents-box video {
  //   height: 40vh;
  // }
  .multiUser {
    right: 23px;
    width: 100px !important;
    height: 70px !important;
    top: 260px;
  }

  .btns-nav button img {
    width: 16px;
    height: 16px;
  }
  .maximize-screen,
  .mute,
  .leave-room,
  .square-btn,
  .camera,
  .unmute,
  .setting-btn {
    height: 32px !important;
    width: 32px !important;
  }
  .signUp-main .input-group select {
    height: 32px;
    border-radius: 5px;
    font-size: 14px;
  }

  .hostImg {
    width: 38px;
    height: 38px;
    border-radius: 12px;
  }
  .partName {
    font-size: 10px;
    left: 10px;
    top: 10px;
  }
  .hostUserImg {
    width: 38px;
    height: 38px;
  }
  .customdashLink li.nav-item {
    padding-left: 1px;
  }
  .sign-right-modal {
    padding: 30px 15px;
  }
  .arledy-signup-txt a {
    color: #36b7bd !important;
    font-size: 13px;
  }
  .signup-prvcy-txt a {
    font-size: 13px;
    text-align: center;
  }
  .signUp-main .input-group img {
    top: 9px;
  }
  .signup-prvcy-txt {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  }
  .arledy-signup-txt {
    font-size: 13px;
  }
  .sign-up-btn {
    font-size: 15px;
    height: auto;
  }
  .signUp-main h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .signupModal.modal-dialog {
    margin: 25px auto !important;
    max-width: 90% !important;
  }
  .signUp-main {
    padding: 0;
  }
  .sign-left-modal {
    order: 1;
    padding: 20px;
  }
  .meetingTitle {
    font-size: 14px;
    margin-top: 30px;
  }
  .signUp-main .input-group input {
    font-size: 13px;
    height: 32px;
    border-radius: 5px;
  }
  .meetingRight {
    float: left;
    padding-left: 0;
    width: 100%;
  }
  .dashRightSubCover {
    float: none;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 0;
    height: 45vh;
    overflow-y: scroll;
  }
  .dashmidSubCover {
    margin-left: 0;
    margin-top: 30px;
  }
  .dashmidSubCover {
    margin-left: 0;
    margin-top: 0;
    max-width: 100%;
    padding: 15px;
  }

  //dashboard page responsive

  .dashmidSubCover {
    margin-left: 0;
    margin-top: 30px;
    margin-right: 0;
  }
  .appHome {
    width: 100%;
  }
  .mainRightCover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .welcomDashTitle {
    font-size: 18px;
  }
  .dashmidCover .skyblueBtn,
  .newMeetingBtn {
    font-size: 15px;
    font-weight: 600;
    height: 45px;
    margin-top: 0px;
  }
  .dashmidCover .skyblueBtn {
    margin-top: 30px;
  }
  .dashmidCover {
    width: 100%;
  }
  .dashRightCover {
    display: flex;
  }
  .dashRightUlCover {
    padding: 0 13px;
  }
  .dashLeftCover {
    background-color: #f1f3f9;
    display: table-cell;
    vertical-align: top;
    width: 100px;
  }
  .dashRightTitle {
    font-size: 16px;
  }
  .meetingRight {
    float: left;
    padding-left: 0;
    width: 100%;
  }
  .meetingTitle {
    font-size: 14px;
    margin-top: 10px;
  }
  .meetingTime {
    font-size: 13px;
    margin-top: 7px;
  }
  .hostTitle {
    font-size: 12px;
    margin-top: 0px;
  }
  .appSidebar {
    display: table;
    height: 100%;
  }
  .dayText {
    font-size: 14px;
  }
  .dashRightUlCover ul li {
    padding-bottom: 20px;
    padding-top: 0;
  }
  .pinkTime,
  .purpleTime,
  .greenTime {
    font-size: 15px;
    height: 55px;
    width: 55px;
  }

  //front page sign in
  .container-fluid {
    padding: 0 15px !important;
  }
  .homeCover {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .premiumTitle {
    font-size: 19px;
    line-height: 30px;
    margin-top: 0;
  }
  .premiumPara {
    font-size: 13px;
    line-height: 22px;
    margin-top: 10px;
  }
  .homeLeftCover {
    float: none;
    margin: 0 auto;
    max-width: 350px;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
  }
  .navbar-light .customColl .navbar-nav .nav-item:first-child a {
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
  }
  .navbar-light .customColl .navbar-nav .nav-link {
    text-align: left;
  }

  // video meeting page  css mobile view

  .parent-box-host {
    padding: 15px;
  }
  .parent-box-host .singleUser #preview video {
    height: 40vh;
  }
  .parent-all-cam {
    height: auto;
  }

  .btns-nav button {
    margin: 0 5px;
  }
  .parent-all-cam {
    height: 50vh;
  }
  .btns-nav {
    bottom: 20px;
  }
  .leave-room {
    line-height: 0px;
  }
  .btns-nav button img {
    width: 16px;
    height: 16px;
  }
  .btns-nav-one {
    display: none;
  }
  .home-main .container {
    max-width: 100%;
  }
  .attachmentBtn {
    top: 7px;
    left: 10px;
  }
}

@media (min-width: 320px) and (max-width: 350px) {
  .dashboardCover {
    display: flex;
  }
}

// tc-media-queries
@media (max-width: 1600px) {
  .modal-dialog.modal-md-addcontact {
    max-width: 600px;
  }
  .modal-dialog.modal-md-invitePeople {
    max-width: 800px;
  }

  .landing-page-title {
    padding: 8px 20px;
    top: 0px;
    min-height: 53px;
    h2 {
      font-size: 18px;
    }
    a {
      font-size: 13px;
      padding: 8px 22px;
      border-radius: 5px;
      margin-left: 20px;
    }
  }

  .gender-radio {
    .custom-radio {
      margin-right: 20px;
      label {
        font-size: 17px;
      }
    }
  }

  .form-submit-btns {
    margin-top: 30px;
    button {
      padding: 18px;
      font-size: 22px;
    }
  }

  .modal-md-invitePeople {
    .file-upload {
      h3 {
        font-size: 1.5rem;
      }
      .btn-bs-file {
        font-size: 1rem;
      }
    }
    .form-group-btn {
      button {
        width: 120px !important;
        height: 50px !important;
        font-size: 17px !important;
      }
    }
    canvas {
      height: 400px;
    }
  }

  .user-profile-listing-wrapper .new-meeting-main h4.sec-heading,
  .user-profile-listing-wrapper .new-meeting-main .newmeeting-form-wrapper {
    padding: 12px 20px !important;
  }
}
@media (max-width: 1366px) {
  .modal-content {
    border-radius: 25px;
  }
  .sign-right-modal {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
  }

  .landing-page-title {
    padding: 6px 20px;
    top: 0px;
    min-height: 48px;
    h2 {
      font-size: 16px;
    }
    a {
      font-size: 12px;
      padding: 6px 20px;
    }
  }

  .modal-dialog.modal-md-invitePeople {
    max-width: 700px;
  }
  .modal-dialog.modal-md-editcontact {
    max-width: 600px;
  }
  .modal-dialog.modal-md-editcontact .modal-content {
    border-radius: 25px;
  }
  .modal-md-contactProfileModal {
    max-width: 470px;
  }

  .modal-md-contactProfileModal .modal-content {
    border-radius: 15px;
    padding: 20px;
  }

  .switch {
    width: 55px;
    height: 30px;
  }
  .slider:before {
    height: 22px;
    width: 22px;
  }

  .gender-radio {
    .custom-radio {
      margin-right: 20px;
      label {
        font-size: 15px;
        padding-left: 30px;
      }
    }
  }

  .custom-radio [type="radio"]:checked + label:before,
  .custom-radio [type="radio"]:not(:checked) + label:before {
    width: 20px;
    height: 20px;
  }
  .custom-radio [type="radio"]:checked + label:after,
  .custom-radio [type="radio"]:not(:checked) + label:after {
    width: 12px;
    height: 12px;
    left: 4px;
  }

  .form-submit-btns {
    margin-top: 30px;
    button {
      padding: 15px;
      font-size: 18px;
    }
  }

  .landing-page-container .noInvite {
    font-size: 15px;
  }

  .time-picker {
    input {
      height: 45px;
    }
    .ngb-tp-meridian {
      button {
        height: 45px;
      }
    }
  }
  .micDiv {
    width: 100px;
    text-align: right;
  }
  .micDiv button:first-child img {
    width: 12px;
  }
  .micDiv button:nth-child(2) img {
    width: 19px;
  }
  .participantsInfo p {
    font-size: 13px;
    line-height: 20px;
  }

  button.btn-chat {
    height: 50px;
    width: 50px;
  }
  textarea#chatMessage {
    padding-left: 60px;
    padding-right: 65px;
    height: 63px !important;
    padding-top: 17px;
  }

  .leftMeetingTitle {
    font-size: 30px;
  }
  .leftMeetingBtnCover {
    margin-top: 30px;
    .skyblueBtn {
      margin: 0 5px;
      width: 180px;
      font-size: 18px;
    }
  }

  .home-modal .home-modal-bdy {
    padding: 30px 30px;
    img {
      margin-bottom: 55px;
    }
    h4 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 1080px) {
  .landing-page-title {
    padding: 5px 20px;
    top: 0px;
    min-height: 40px;
    h2 {
      font-size: 15px;
    }
    a {
      font-size: 11px;
      padding: 5px 20px;
    }
  }

  .modal-md-contactProfileModal {
    max-width: 430px;
  }

  .modal-md-contactProfileModal .modal-content {
    border-radius: 10px;
    padding: 15px;
  }

  .user-profile-listing-wrapper .new-meeting-main h4.sec-heading,
  .user-profile-listing-wrapper .new-meeting-main .newmeeting-form-wrapper {
    padding: 10px 15px !important;
  }

  textarea#chatMessage {
    padding-left: 50px;
    padding-right: 60px;
    height: 60px !important;
    padding-top: 15px;
  }

  .leftMeetingTitle {
    font-size: 28px;
  }
  .leftMeetingBtnCover {
    margin-top: 30px;
    .skyblueBtn {
      border-radius: 8px;
      width: 160px;
      font-size: 17px;
      height: 55px;
    }
  }

  .home-modal .home-modal-bdy {
    padding: 25px;
    img {
      margin-bottom: 30px;
    }
    h4 {
      font-size: 25px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
    }
    .skyblueBtn {
      font-size: 18px;
      height: 55px;
    }
  }
}

@media (max-width: 991px) {
  .landing-page-title {
    padding: 5px 15px;
    top: 0px;
    min-height: 39px;
    h2 {
      font-size: 15px;
    }
    a {
      font-size: 11px;
      padding: 5px 20px;
      margin-left: 15px;
    }
  }

  .sign-right-modal {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 30px 15px;
    border-bottom-left-radius: 0;
  }
  .modal-dialog.modal-dialog-centered.modal-lg-login .modal-content,
  .modal-dialog.modal-md-editcontact .modal-content {
    border-radius: 15px;
  }

  .switch {
    width: 48px;
    height: 26px;
  }
  .slider:before {
    height: 20px;
    width: 20px;
    bottom: 3px;
  }
  input:checked + .slider:before {
    transform: translateX(21px);
  }

  .gender-radio {
    .custom-radio {
      margin-right: 15px;
      label {
        font-size: 15px;
        padding-left: 25px;
      }
    }
  }

  .gridDropdown .dropdown-menu .dropdown-item {
    padding: 4px ​15px !important;
    img {
      width: 20px;
    }
  }
}

@media (max-width: 767px) {
  .modal-dialog.modal-md-addcontact .modal-content,
  .modal-dialog.modal-md-invitePeople .modal-content,
  .modal-dialog.modal-md-editcontact .modal-content {
    border-radius: 10px;
  }
  .input-group .placeholder {
    font-size: 11px;
  }

  .landing-page-container .noInvite {
    font-size: 12px;
  }

  .user-profile-listing-wrapper .new-meeting-main .newmeeting-form-wrapper {
    padding: 10px 15px !important;
  }

  .leftMeetingTitle {
    font-size: 25px;
  }
  .leftMeetingBtnCover {
    margin-top: 30px;
    .skyblueBtn {
      border-radius: 5px;
      width: 140px;
      font-size: 16px;
      height: 50px;
    }
  }

  .home-modal .home-modal-bdy {
    padding: 20px;
    img {
      margin-bottom: 25px;
    }
    h4 {
      font-size: 22px;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 15px;
    }
    .skyblueBtn {
      font-size: 16px;
      height: 50px;
    }
  }
}

@media (max-width: 640px) {
  .form-submit-btns {
    margin-top: 20px;
    button {
      padding: 12px;
      font-size: 16px;
    }
  }
  .modal-content {
    border-radius: 15px;
  }
}

@media (max-width: 480px) {
  .gender-radio {
    flex-wrap: wrap;
  }

  .leftMeetingTitle {
    font-size: 22px;
  }
  .leftMeetingBtnCover {
    margin-top: 20px;
    .skyblueBtn {
      border-radius: 5px;
      width: 120px;
      font-size: 15px;
      height: 45px;
    }
  }
}
.profile-toltip-main .tooltip-inner {
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  color: #000 !important;
  text-align: left;
  background-color: #fff !important;
  border-radius: 0.25rem;
  box-shadow: 5px 4px 18px #0000001a;
  opacity: 1;
}
.profile-toltip-main .bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}
.profile-toltip-main .bs-tooltip-right .arrow {
  box-shadow: 5px 4px 18px #0000001a;
  opacity: 1;
}
.profile-toltip-main .bs-tooltip-right {
  margin-left: 10px;
  width: 170px;
  opacity: 1;
}
.profile-toltip-main {
  align-items: center;
}
.anc-pointer {
  cursor: pointer;
}
